import { useContext, useEffect, useState } from "react";
import { CognitoSessionContext } from "../../../contexts/CognitoSession";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { CreateEquipmentDrawer } from "../../../components/AdminComponents";
import { SessionButton } from "../../../components/SessionComponents";
import { getEquipments, postEquipments } from "../../../services/Equipments";

export default function EquipmentsTab({ activeTab }) {
  const [userEquipments, setUserEquipments] = useState([]);
  const [formData, setFormData] = useState({
    equipment_type: "Máquina",
    vehicle_type: "",
    model: "",
    chassis: "",
    mileage: "",
    dumpster_type: "",
    volume: "",
    equipment_code: "",
    manufacturer: "",
    year_of_manufacture: "",
    color: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const cognitoUser = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  async function fetchEquipments() {
    const response = await getEquipments(
      cognitoUser.idToken.jwtToken,
      meetalUser.org_id,
      logOut
    );

    setUserEquipments(response);
  }

  useEffect(() => {
    if (activeTab === "Equipments") {
      fetchEquipments();
    }
  }, [activeTab]);

  const { logOut } = useContext(CognitoSessionContext);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  async function sendForm() {
    for (let key in formData) {
      if (!formData[key]) {
        delete formData[key];
      }
    }

    const body = {
      ...formData,
      org_id: meetalUser.org_id,
      admin_id: meetalUser.meetal_user_id,
    };

    console.log(body);

    const response = await postEquipments(
      cognitoUser.idToken.jwtToken,
      body,
      logOut
    );
    console.log(response);
    if (response.data.statusCode !== 200) {
      console.log("erro");
    } else {
      console.log("sucesso");
    }
  }

  return (
    <>
      <SessionButton width="200px" onClick={onOpen}>
        Adicionar equipamento
      </SessionButton>
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        borderRadius="8px"
        padding="15px"
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Tipo</Th>
              <Th>Fabricante</Th>
              <Th>Ano de fabricação</Th>
              <Th>Cor</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userEquipments.map((e) => (
              <Tr key={e.equip_id}>
                <Td>{e.equipment_code}</Td>
                <Td>{e.equipment_type}</Td>
                <Td>{e.manufacturer}</Td>
                <Td>{e.year_of_manufacture}</Td>
                <Td>{e.color}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <CreateEquipmentDrawer
        isOpen={isOpen}
        onClose={onClose}
        formData={formData}
        setFormData={setFormData}
        handleInputChange={handleInputChange}
        saveEquipment={sendForm}
      />
    </>
  );
}
