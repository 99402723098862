import { BrowserRouter, Route, Routes } from "react-router-dom";
import LogIn from "./pages/LogIn/LogIn";
import CognitoUserProvider from "./contexts/CognitoUser";
import CognitoSessionProvider from "./contexts/CognitoSession";
import SignUp from "./pages/SignUp/SignUp";
import ForgotPassword from "./pages/LogIn/ForgotPassword";
import EmailConfirmation from "./pages/SignUp/EmailConfirmation";
import Authenticator from "./components/Autheticator";
import UnderReview from "./pages/LogIn/UnderReview";
import CheckSession from "./components/CheckSession";
import SucateiroSettings from "./pages/Admin/SucateiroSettings";
import BranchPage from "./pages/Admin/BranchPage";
import UsersPage from "./pages/Admin/UsersPage";
import RegistrationsPage from "./pages/Admin/RegistrationsPage";
import TransportsPage from "./pages/Admin/TransportsPage";
import DumpstersPage from "./pages/Admin/DumpstersPage";
import StockPage from "./pages/Admin/StockPage";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <CognitoUserProvider>
          <CognitoSessionProvider>
            <Routes>
              <Route
                path="/cadastro"
                element={
                  <CheckSession>
                    <SignUp />
                  </CheckSession>
                }
              ></Route>
              <Route
                path="/entrar"
                element={
                  <CheckSession>
                    <LogIn />
                  </CheckSession>
                }
              ></Route>
              <Route
                path="/cadastro/verificar-conta"
                element={
                  <CheckSession>
                    <EmailConfirmation />
                  </CheckSession>
                }
              ></Route>
              <Route
                path="/entrar/esqueci-senha"
                element={
                  <CheckSession>
                    <ForgotPassword />
                  </CheckSession>
                }
              ></Route>
              <Route
                path="/entrar/em-analise"
                element={
                  <CheckSession>
                    <UnderReview />
                  </CheckSession>
                }
              ></Route>
              {/* <Route
                path="/admin/unidades"
                element={
                  <Authenticator>
                    <BranchPage />
                  </Authenticator>
                }
              ></Route>
              <Route
                path="/admin/usuarios"
                element={
                  <Authenticator>
                    <UsersPage />
                  </Authenticator>
                }
              ></Route> */}
              <Route
                path="/admin/configuracoes"
                element={
                  <Authenticator>
                    <SucateiroSettings />
                  </Authenticator>
                }
              ></Route>
              <Route
                path="/admin/cadastros"
                element={
                  <Authenticator>
                    <RegistrationsPage />
                  </Authenticator>
                }
              ></Route>
              <Route
                path="/admin/transportes"
                element={
                  <Authenticator>
                    <TransportsPage />
                  </Authenticator>
                }
              ></Route>
              <Route
                path="/admin/cacambas"
                element={
                  <Authenticator>
                    <DumpstersPage />
                  </Authenticator>
                }
              ></Route>
              <Route
                path="/admin/estoque"
                element={
                  <Authenticator>
                    <StockPage />
                  </Authenticator>
                }
              ></Route>
            </Routes>
          </CognitoSessionProvider>
        </CognitoUserProvider>
      </BrowserRouter>
    </>
  );
}
