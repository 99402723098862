import axios from "axios";
import checkIfSessionIsValid from "../utils/checkIfSessionIsValid";

export async function postTransport(idToken, body, logOut) {
  const url = `https://trsztzz9va.execute-api.sa-east-1.amazonaws.com/prod/transport`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const JsonBody = JSON.stringify(body);

  const promise = axios.post(url, JsonBody, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}

export async function getTransport(idToken, branch_id, transport_type, logOut) {
  const url = `https://trsztzz9va.execute-api.sa-east-1.amazonaws.com/prod/transport?branch_id=${branch_id}&transport_type=${transport_type}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const promise = axios.get(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return JSON.parse(response.data.body);
}

export async function finishTransport(idToken, body, logOut) {
  const url = `https://trsztzz9va.execute-api.sa-east-1.amazonaws.com/prod/transport`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const JsonBody = JSON.stringify(body);

  const promise = axios.put(url, JsonBody, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}
