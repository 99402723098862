import { Button } from "@chakra-ui/react";
import { brandColorGreen } from "../GlobalStyles";

export default function SidebarSubmenuButton({
  children,
  onClick,
  marginTop,
  selected,
}) {
  return (
    <Button
      size="md"
      marginTop={marginTop}
      variant={selected ? "solid" : "ghost"}
      color={selected ? brandColorGreen : "gray.500"}
      backgroundColor={selected ? "gray.100" : "transparent"}
      _hover={{
        color: brandColorGreen,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
