import {
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import Header from "../../components/Global/Header";
import Sidebar from "../../components/Global/Sidebar";
import AdminPageContainer from "../../components/Global/AdminPageContainer";
import SubHeader from "../../components/Global/SubHeader";
import AdminPageContent from "../../components/Global/AdminPageContent";
import Footer from "../../components/Global/Footer";
import { getEquipmentsByType } from "../../services/Equipments";
import dayjs from "dayjs";

export default function DumpstersPage() {
  const [userDumpsters, setUserDumpsters] = useState([]);

  const cognitoUser = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  async function fetchDumpsters() {
    const response = await getEquipmentsByType(
      cognitoUser.idToken.jwtToken,
      meetalUser.org_id,
      "Caçamba",
      "",
      "",
      logOut
    );

    console.log(response);

    setUserDumpsters(response);
  }

  useEffect(() => {
    fetchDumpsters();
  }, []);

  const { logOut } = useContext(CognitoSessionContext);

  return (
    <>
      <Header />
      <HStack>
        <Sidebar />
        <AdminPageContainer>
          <SubHeader>Caçambas</SubHeader>
          <AdminPageContent>
            <TableContainer
              border="1px solid"
              borderColor="gray.200"
              borderRadius="8px"
              padding="15px"
            >
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Código</Th>
                    <Th>Tipo</Th>
                    <Th>Apelido do agente</Th>
                    <Th>Localização</Th>
                    <Th>Bairro</Th>
                    <Th>Data de envio</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {userDumpsters.map((d) => (
                    <Tr key={d.equipment_code}>
                      <Td>{d.equipment_code}</Td>
                      <Td>{d.dumpster_type}</Td>
                      <Td>
                        {d.last_location ? d.last_location.agent_id : "---"}
                      </Td>
                      <Td>
                        {d.last_location ? d.last_location.address : "---"}
                      </Td>
                      <Td>
                        {d.last_location ? d.last_location.neighborhood : "---"}
                      </Td>
                      <Td>
                        {d.last_location
                          ? dayjs
                              .unix(d.last_location.timestamp)
                              .format("DD/MM/YYYY")
                          : "---"}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </AdminPageContent>
          <Footer />
        </AdminPageContainer>
      </HStack>
    </>
  );
}
