import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import Header from "../../components/Global/Header";
import Sidebar from "../../components/Global/Sidebar";
import AdminPageContainer from "../../components/Global/AdminPageContainer";
import SubHeader from "../../components/Global/SubHeader";
import AdminPageContent from "../../components/Global/AdminPageContent";
import Footer from "../../components/Global/Footer";
import {
  AddMaterialDrawer,
  ConvertMaterialDrawer,
  DeleteMaterialDrawer,
} from "../../components/AdminComponents";
import {
  getMaterialsByType,
  getStockMaterials,
  putMaterialQuantity,
} from "../../services/Materials";

export default function StockPage() {
  const [userMaterials, setUserMaterials] = useState([]);
  const [searchStates, setSearchStates] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [addMaterialFormData, setAddMaterialFormData] = useState({
    material_id: "",
    quantity: "",
    timestamp: "",
    observations: "",
  });
  const [deleteMaterialFormData, setDeleteMaterialFormData] = useState({
    material_id: "",
    quantity: "",
    timestamp: "",
    observations: "",
  });
  const [convertMaterialFormData, setConvertMaterialFormData] = useState({
    initial_material_id: "",
    final_material_id: "",
    gross_quantity: "",
    discounted_quantity: "",
    timestamp: "",
    observations: "",
  });
  const [net_quantity, setNet_quantity] = useState(0);
  const {
    isOpen: drawerAddMaterialIsOpen,
    onOpen: drawerAddMaterialOnOpen,
    onClose: drawerAddMaterialOnClose,
  } = useDisclosure();
  const {
    isOpen: drawerDeleteMaterialIsOpen,
    onOpen: drawerDeleteMaterialOnOpen,
    onClose: drawerDeleteMaterialOnClose,
  } = useDisclosure();
  const {
    isOpen: drawerConvertMaterialIsOpen,
    onOpen: drawerConvertMaterialOnOpen,
    onClose: drawerConvertMaterialOnClose,
  } = useDisclosure();

  const cognitoUser = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  const toast = useToast();

  async function fetchMaterials() {
    const response = await getStockMaterials(
      cognitoUser.idToken.jwtToken,
      meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
      logOut
    );

    setUserMaterials(response);
  }

  useEffect(() => {
    fetchMaterials();
  }, []);

  useEffect(() => {
    if (convertMaterialFormData.gross_quantity) {
      calculateNetQuantity();
    }
  }, [convertMaterialFormData]);

  function calculateNetQuantity() {
    setNet_quantity(
      convertMaterialFormData.gross_quantity -
        convertMaterialFormData.discounted_quantity
    );
  }

  const { logOut } = useContext(CognitoSessionContext);

  function handleInputChange(e, formData, setFormData) {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const debounceTimeoutRef = useRef(null);

  function handleSearchInput(e, name) {
    const value = e.target.value;
    let newSearchState = {
      ...searchStates,
      [name]: {
        ...searchStates[name],
        query: value,
        loading: true,
        results: [],
      },
    };
    setSearchStates(newSearchState);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(async () => {
      if (value.length > 1) {
        const response = await getMaterialsByType(
          cognitoUser.idToken.jwtToken,
          meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
          value,
          logOut
        );
        console.log(response);

        newSearchState = {
          ...newSearchState,
          [name]: {
            ...newSearchState[name],
            loading: false,
            results: response,
          },
        };
        setSearchStates(newSearchState);
      } else {
        newSearchState = {
          ...newSearchState,
          [name]: {
            ...newSearchState[name],
            loading: false,
            results: [],
          },
        };
        setSearchStates(newSearchState);
      }
    }, 1000);
  }

  function selectListItem(
    formData,
    setFormData,
    { formDataName, id, propName, queryDisplay }
  ) {
    const newFormData = { ...formData, [formDataName]: id };
    setFormData(newFormData);
    let newSearchState = {
      ...searchStates,
      [propName]: {
        ...searchStates[propName],
        query: queryDisplay,
        results: [],
      },
    };
    setSearchStates(newSearchState);
  }

  async function sendMaterialAddition() {
    const body = {
      ...addMaterialFormData,
      org_id: meetalUser.org_id,
      admin_id: meetalUser.meetal_user_id,
      branch_id: meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
    };

    for (let key in body) {
      if (!body[key]) {
        delete body[key];
      }
    }
    console.log(body);

    const response = await putMaterialQuantity(
      cognitoUser.idToken.jwtToken,
      body,
      logOut
    );
    console.log(response);
    if (response.data.statusCode !== 200) {
      toast({
        title: "Erro ao salvar",
        description: "Tente novamente",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
    } else {
      toast({
        title: "Material adicionado com sucesso",
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
    }
    setButtonLoading(false);
  }

  async function sendMaterialDeletion() {
    const body = {
      ...deleteMaterialFormData,
      org_id: meetalUser.org_id,
      admin_id: meetalUser.meetal_user_id,
      branch_id: meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
      quantity: -deleteMaterialFormData.quantity,
    };

    for (let key in body) {
      if (!body[key]) {
        delete body[key];
      }
    }
    console.log(body);

    const response = await putMaterialQuantity(
      cognitoUser.idToken.jwtToken,
      body,
      logOut
    );
    console.log(response);
    if (response.data.statusCode !== 200) {
      toast({
        title: "Erro ao salvar",
        description: "Tente novamente",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
    } else {
      toast({
        title: "Material deletado com sucesso",
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
    }
    setButtonLoading(false);
  }

  async function sendMaterialConversion() {
    setButtonLoading(true);
    const deletionBody = {
      org_id: meetalUser.org_id,
      admin_id: meetalUser.meetal_user_id,
      branch_id: meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
      material_id: convertMaterialFormData.initial_material_id,
      quantity: -convertMaterialFormData.gross_quantity,
      timestamp: convertMaterialFormData.timestamp,
      observations: convertMaterialFormData.observations,
    };

    for (let key in deletionBody) {
      if (!deletionBody[key]) {
        delete deletionBody[key];
      }
    }
    console.log(deletionBody);

    const additionBody = {
      org_id: meetalUser.org_id,
      admin_id: meetalUser.meetal_user_id,
      branch_id: meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
      material_id: convertMaterialFormData.final_material_id,
      quantity: net_quantity,
      timestamp: convertMaterialFormData.timestamp,
      observations: convertMaterialFormData.observations,
    };

    for (let key in additionBody) {
      if (!additionBody[key]) {
        delete additionBody[key];
      }
    }
    console.log(additionBody);

    //!encadear reqs

    const deletionResponse = await putMaterialQuantity(
      cognitoUser.idToken.jwtToken,
      deletionBody,
      logOut
    );
    console.log(deletionResponse);
    if (deletionResponse.data.statusCode !== 200) {
      toast({
        title: "Erro ao salvar",
        description: "Tente novamente",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
    } else {
      const additionResponse = await putMaterialQuantity(
        cognitoUser.idToken.jwtToken,
        additionBody,
        logOut
      );
      console.log(additionResponse);
      if (additionResponse.data.statusCode !== 200) {
        toast({
          title: "Erro ao salvar",
          description: "Tente novamente",
          status: "error",
          position: "bottom-right",
          isClosable: true,
        });
      } else {
        toast({
          title: "Material convertido com sucesso",
          status: "success",
          position: "bottom-right",
          isClosable: true,
        });
      }
    }
    setButtonLoading(false);
  }

  return (
    <>
      <Header />
      <HStack>
        <Sidebar />
        <AdminPageContainer>
          <SubHeader>Estoque</SubHeader>
          <AdminPageContent>
            <Menu>
              <MenuButton as={Button}>Ajustar estoque</MenuButton>
              <MenuList>
                <MenuItem
                  color="gray.800"
                  _hover={{
                    backgroundColor: "gray.200",
                  }}
                  onClick={drawerAddMaterialOnOpen}
                >
                  Adição manual
                </MenuItem>
                <MenuItem
                  color="gray.800"
                  _hover={{
                    backgroundColor: "gray.200",
                  }}
                  onClick={drawerDeleteMaterialOnOpen}
                >
                  Deleção manual
                </MenuItem>
                <MenuItem
                  color="gray.800"
                  _hover={{
                    backgroundColor: "gray.200",
                  }}
                  onClick={drawerConvertMaterialOnOpen}
                >
                  Conversão
                </MenuItem>
              </MenuList>
            </Menu>
            <TableContainer
              border="1px solid"
              borderColor="gray.200"
              borderRadius="8px"
              padding="15px"
            >
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Código</Th>
                    <Th>Nome do material</Th>
                    <Th>Grupo</Th>
                    <Th>Quantidade</Th>
                    <Th>Unidade</Th>
                    <Th>Valor</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {userMaterials.map((m) => (
                    <Tr key={m.material_id}>
                      <Td>{m.material_id.split("-")[0]}</Td>
                      <Td>{m.material_name}</Td>
                      <Td>{m.label_name}</Td>
                      <Td>{m.quantity}</Td>
                      <Td>{m.measure_unit}</Td>
                      <Td>--</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </AdminPageContent>
          <Footer />
        </AdminPageContainer>
      </HStack>
      <AddMaterialDrawer
        isOpen={drawerAddMaterialIsOpen}
        onClose={drawerAddMaterialOnClose}
        formData={addMaterialFormData}
        setFormData={setAddMaterialFormData}
        selectListItem={selectListItem}
        searchStates={searchStates}
        handleSearchInput={handleSearchInput}
        handleInputChange={handleInputChange}
        sendMaterialAddition={sendMaterialAddition}
        buttonLoading={buttonLoading}
      />
      <DeleteMaterialDrawer
        isOpen={drawerDeleteMaterialIsOpen}
        onClose={drawerDeleteMaterialOnClose}
        formData={deleteMaterialFormData}
        setFormData={setDeleteMaterialFormData}
        selectListItem={selectListItem}
        searchStates={searchStates}
        handleSearchInput={handleSearchInput}
        handleInputChange={handleInputChange}
        sendMaterialDeletion={sendMaterialDeletion}
        buttonLoading={buttonLoading}
      />
      <ConvertMaterialDrawer
        isOpen={drawerConvertMaterialIsOpen}
        onClose={drawerConvertMaterialOnClose}
        formData={convertMaterialFormData}
        setFormData={setConvertMaterialFormData}
        net_quantity={net_quantity}
        selectListItem={selectListItem}
        searchStates={searchStates}
        handleSearchInput={handleSearchInput}
        handleInputChange={handleInputChange}
        sendMaterialConversion={sendMaterialConversion}
        buttonLoading={buttonLoading}
      />
    </>
  );
}
