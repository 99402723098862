import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  HStack,
  IconButton,
  Image,
  Radio,
  RadioGroup,
  Text,
  VStack,
  Divider,
  Checkbox,
  Tag,
  Flex,
  Icon,
  Tooltip,
  TableContainer,
  Table,
  Tr,
  Th,
  Thead,
  Tbody,
  Td,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Link,
  ModalCloseButton,
  Editable,
  EditablePreview,
  EditableInput,
  ButtonGroup,
  useEditableControls,
} from "@chakra-ui/react";
import {
  FiTrash2,
  FiPlus,
  FiMapPin,
  FiCheck,
  FiEdit,
  FiX,
  FiMoreVertical,
} from "react-icons/fi";
import {
  HiCash,
  HiCheckCircle,
  HiExclamationCircle,
  HiInformationCircle,
  HiMinusCircle,
} from "react-icons/hi";
import { brandColorBlue, brandColorOrange } from "../GlobalStyles";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import {
  SessionButton,
  SessionFormLabel,
  SessionInput,
} from "./SessionComponents";
import {
  AlertComponent,
  InputFileComponent,
  InputWithAddonComponent,
  MultiSelectSearchComponent,
  NumberInputComponent,
  OutlineButtonComponent,
  SearchComponent,
  SelectComponent,
  TextareaComponent,
} from "./GlobalComponents";
import defaultImage from "../assets/default-image.jpeg";
import Select from "react-select";

dayjs.extend(duration);

export function AdminBadge({
  children,
  colorScheme,
  textColor,
  icon,
  iconColor,
}) {
  return (
    <Badge
      textTransform="initial"
      variant="subtle"
      display="flex"
      alignItems="center"
      colorScheme={colorScheme}
      borderRadius="4px"
      padding="0 5px"
      gap="2px"
    >
      <Icon as={icon} boxSize={4} color={iconColor} />
      <Text fontSize="sm" fontWeight="600" color={textColor}>
        {children}
      </Text>
    </Badge>
  );
}

export function ScrapCard({
  images,
  id,
  status,
  title,
  branch,
  expiration_timestamp,
  buttonOffers,
  buttonDelete,
}) {
  const numberOfMilisecondsInAWeek = 86400000 * 7;
  const timeRemaining = expiration_timestamp * 1000 - Date.now();

  const timeInDays = dayjs.duration(timeRemaining).days();
  const timeInHours = dayjs.duration(timeRemaining).hours();
  const timeInMinutes = dayjs.duration(timeRemaining).minutes();

  const dateExpired = dayjs(expiration_timestamp * 1000).format("DD/MM/YYYY");
  const timeExpired = dayjs(expiration_timestamp * 1000).format("HH:mm");

  const timeRemainingToAccepOffer =
    expiration_timestamp * 1000 + numberOfMilisecondsInAWeek - Date.now();

  const timeInDaysToAcceptOffer = dayjs
    .duration(timeRemainingToAccepOffer)
    .days();
  const timeInHoursToAcceptOffer = dayjs
    .duration(timeRemainingToAccepOffer)
    .hours();
  const timeInMinutesToAcceptOffer = dayjs
    .duration(timeRemainingToAccepOffer)
    .minutes();

  return (
    <Card width="95%" backgroundColor="white" marginTop="10px">
      <HStack>
        <CardHeader display="flex" alignItems="center" gap="25px">
          <Image
            objectFit="cover"
            width="130px"
            height="130px"
            borderRadius="4px"
            src={images ? images[0] : defaultImage}
          />
          <VStack alignItems="start" marginBottom="15px" gap="15px">
            <HStack>
              <Text fontSize="sm" fontWeight="500" color="gray.500">
                ID: {id.split("-")[0]}
              </Text>
              {(status === "Ativo" && (
                <AdminBadge
                  colorScheme="green"
                  textColor="green.800"
                  icon={HiCheckCircle}
                  iconColor="green.500"
                >
                  {status}
                </AdminBadge>
              )) ||
                (status === "Aprovação pendente" &&
                  ((timeInDaysToAcceptOffer <= 0 &&
                    timeInHoursToAcceptOffer <= 0 &&
                    timeInMinutesToAcceptOffer <= 0 && (
                      <AdminBadge
                        colorScheme="orange"
                        textColor="orange.800"
                        icon={HiExclamationCircle}
                        iconColor="orange.500"
                      >
                        Você tem 1 minuto para aceitar uma oferta
                      </AdminBadge>
                    )) ||
                    (timeInDaysToAcceptOffer <= 0 &&
                      timeInHoursToAcceptOffer <= 0 && (
                        <AdminBadge
                          colorScheme="orange"
                          textColor="orange.800"
                          icon={HiExclamationCircle}
                          iconColor="orange.500"
                        >
                          Você tem {timeInMinutesToAcceptOffer} minutos para
                          aceitar uma oferta
                        </AdminBadge>
                      )) ||
                    (timeInDaysToAcceptOffer <= 0 && (
                      <AdminBadge
                        colorScheme="orange"
                        textColor="orange.800"
                        icon={HiExclamationCircle}
                        iconColor="orange.500"
                      >
                        Você tem {timeInHoursToAcceptOffer} horas para aceitar
                        uma oferta
                      </AdminBadge>
                    )) ||
                    (timeInDaysToAcceptOffer > 0 && (
                      <AdminBadge
                        colorScheme="orange"
                        textColor="orange.800"
                        icon={HiExclamationCircle}
                        iconColor="orange.500"
                      >
                        Você tem {timeInDaysToAcceptOffer} dias para aceitar uma
                        oferta
                      </AdminBadge>
                    )))) ||
                (status === "Expirado" && (
                  <AdminBadge
                    colorScheme="red"
                    textColor="red.700"
                    icon={HiMinusCircle}
                    iconColor="red.600"
                  >
                    {status}
                  </AdminBadge>
                )) ||
                (status === "Vendido" && (
                  <AdminBadge
                    textColor="gray.500"
                    icon={HiCash}
                    iconColor="gray.500"
                  >
                    {status}
                  </AdminBadge>
                ))}
            </HStack>
            <Box maxWidth="300px">
              <AdminTooltip text={title}>
                <Text
                  fontSize="xl"
                  fontWeight="600"
                  color="gray.700"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {title}
                </Text>
              </AdminTooltip>
            </Box>
          </VStack>
        </CardHeader>
        <CardBody>
          <HStack gap="5vw">
            <VStack>
              <Text fontSize="xs" fontWeight="bold" color="gray.500">
                UNIDADE GERADORA
              </Text>
              <Text fontSize="lg" fontWeight="600" color="gray.700">
                Unidade {branch}
              </Text>
            </VStack>
            <VStack>
              {status === "Ativo" ? (
                <>
                  <Text fontSize="xs" fontWeight="bold" color="gray.500">
                    TEMPO RESTANTE
                  </Text>
                  {timeInDays !== 0 ||
                  timeInHours !== 0 ||
                  timeInMinutes !== 0 ? (
                    <Text fontSize="lg" fontWeight="600" color="gray.700">
                      {timeInDays} Dias {timeInHours} Horas {timeInMinutes} Min
                    </Text>
                  ) : (
                    <Text fontSize="lg" fontWeight="600" color="gray.700">
                      menos de 1 min
                    </Text>
                  )}
                </>
              ) : (
                <>
                  <Text fontSize="xs" fontWeight="bold" color="gray.500">
                    ENCERRADO EM
                  </Text>
                  <Text fontSize="lg" fontWeight="600" color="gray.700">
                    {dateExpired} às {timeExpired}
                  </Text>
                </>
              )}
            </VStack>
          </HStack>
        </CardBody>
        <CardFooter gap="15px">
          {status === "Aprovação pendente" ? (
            <Button
              variant="outline"
              color={brandColorBlue}
              border="1px solid"
              borderColor={brandColorBlue}
              onClick={buttonOffers}
            >
              Ofertas
            </Button>
          ) : (
            <></>
          )}
          <IconButton
            size="md"
            backgroundColor="gray.100"
            color={brandColorBlue}
            _hover={{
              backgroundColor: "gray.300",
            }}
            icon={<FiTrash2 />}
            onClick={buttonDelete}
          />
        </CardFooter>
      </HStack>
    </Card>
  );
}

export function AdminModal({
  isOpen,
  onClose,
  headerText,
  bodyText,
  closeButtonOnClick,
  closeButtonText,
  acceptButtonOnClick,
  acceptButtonText,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="bold" color="gray.700">
          {headerText}
        </ModalHeader>
        <ModalBody fontSize="md" color="gray.700">
          {bodyText}
        </ModalBody>
        <ModalFooter gap="10px">
          <Button width="100%" variant="outline" onClick={closeButtonOnClick}>
            {closeButtonText}
          </Button>
          <Button
            width="100%"
            backgroundColor={brandColorBlue}
            color="white"
            _hover={{
              backgroundColor: "gray.900",
            }}
            onClick={acceptButtonOnClick}
          >
            {acceptButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function OffersDrawer({
  isOpen,
  onClose,
  offers,
  chosenScrap,
  buttonAccept,
  chosenOfferId,
  setChosenOfferId,
  buttonDisabled,
  buttonLoading,
}) {
  function formatPrice(value) {
    const onlyDigits = value
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
    return maskCurrency(digitsFloat);
  }

  function maskCurrency(valor, locale = "pt-BR", currency = "BRL") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(valor);
  }
  const formattedOffers = offers.map((o) => {
    return { ...o, current_bid: formatPrice(String(o.current_bid * 100)) };
  });

  const numberOfMilisecondsInADay = 86400000 * 7;

  const timeRemainingToAccepOffer =
    chosenScrap.expiration_timestamp * 1000 +
    numberOfMilisecondsInADay -
    Date.now();

  const timeInDaysToAcceptOffer = dayjs
    .duration(timeRemainingToAccepOffer)
    .days();
  const timeInHoursToAcceptOffer = dayjs
    .duration(timeRemainingToAccepOffer)
    .hours();
  const timeInMinutesToAcceptOffer = dayjs
    .duration(timeRemainingToAccepOffer)
    .minutes();

  return (
    <>
      {offers ? (
        <Drawer
          size={{ sm: "sm", md: "md", lg: "lg" }}
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <HStack>
                <Text fontSize="3xl" fontWeight="600" color={brandColorBlue}>
                  Ofertas ( {offers.length} )
                </Text>
                {(timeInDaysToAcceptOffer <= 0 &&
                  timeInHoursToAcceptOffer <= 0 &&
                  timeInMinutesToAcceptOffer <= 0 && (
                    <AdminBadge
                      colorScheme="orange"
                      textColor="orange.800"
                      icon={HiExclamationCircle}
                      iconColor="orange.500"
                    >
                      Você tem 1 minuto para aceitar uma oferta
                    </AdminBadge>
                  )) ||
                  (timeInDaysToAcceptOffer <= 0 &&
                    timeInHoursToAcceptOffer <= 0 && (
                      <AdminBadge
                        colorScheme="orange"
                        textColor="orange.800"
                        icon={HiExclamationCircle}
                        iconColor="orange.500"
                      >
                        Você tem {timeInMinutesToAcceptOffer} minutos para
                        aceitar uma oferta
                      </AdminBadge>
                    )) ||
                  (timeInDaysToAcceptOffer <= 0 && (
                    <AdminBadge
                      colorScheme="orange"
                      textColor="orange.800"
                      icon={HiExclamationCircle}
                      iconColor="orange.500"
                    >
                      Você tem {timeInHoursToAcceptOffer} horas para aceitar uma
                      oferta
                    </AdminBadge>
                  )) ||
                  (timeInDaysToAcceptOffer > 0 && (
                    <AdminBadge
                      colorScheme="orange"
                      textColor="orange.800"
                      icon={HiExclamationCircle}
                      iconColor="orange.500"
                    >
                      Você tem {timeInDaysToAcceptOffer} dias para aceitar uma
                      oferta
                    </AdminBadge>
                  ))}
              </HStack>
              <Text fontSize="sm" fontWeight="400" color="gray.700">
                Selecione a oferta de preferência e clique em aceitar
              </Text>
            </DrawerHeader>
            <DrawerBody>
              <RadioGroup value={chosenOfferId} onChange={setChosenOfferId}>
                {formattedOffers.map((o) => (
                  <Radio
                    key={o.oferta_id}
                    width="100%"
                    colorScheme="green"
                    value={o.oferta_id}
                  >
                    <Box
                      padding="15px 30px"
                      border="1px solid"
                      borderColor="gray.200"
                      borderRadius="8px"
                    >
                      <HStack gap="25px">
                        <VStack alignItems="start">
                          <Text
                            fontSize="md"
                            fontWeight="600"
                            color={brandColorBlue}
                          >
                            Ofertante
                          </Text>
                          <Text fontSize="md" color="gray.700">
                            {o.nome_cnpj}
                          </Text>
                        </VStack>
                        <VStack alignItems="start">
                          <Text
                            fontSize="md"
                            fontWeight="600"
                            color={brandColorBlue}
                          >
                            Valor total estiamdo
                          </Text>
                          <Text fontSize="md" fontWeight="600" color="#00A887">
                            {o.current_bid}
                          </Text>
                        </VStack>
                      </HStack>
                    </Box>
                  </Radio>
                ))}
              </RadioGroup>
            </DrawerBody>

            <DrawerFooter>
              <SessionButton
                onClick={buttonAccept}
                isDisabled={buttonDisabled}
                isLoading={buttonLoading}
              >
                Aceitar
              </SessionButton>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      ) : (
        <></>
      )}
    </>
  );
}

export function WhiteContainer({ children, width }) {
  return (
    <Box
      width={width}
      padding="25px"
      margin="25px"
      backgroundColor="white"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="8px"
    >
      {children}
    </Box>
  );
}

export function WhiteContainerHeading({ children }) {
  return (
    <Text fontSize="2xl" fontWeight="600" color={brandColorBlue}>
      {children}
    </Text>
  );
}

export function WhiteContainerSubHeading({ children }) {
  return (
    <Text fontSize="lg" fontWeight="600" color={brandColorBlue}>
      {children}
    </Text>
  );
}

export function WhiteContainerText({ children, isLabel }) {
  return (
    <Text
      fontSize="md"
      fontWeight={isLabel ? "600" : "400"}
      color={isLabel ? brandColorBlue : "gray.600"}
      marginBottom={isLabel ? "5px" : "0"}
    >
      {children}
    </Text>
  );
}

export function PageDivider() {
  return <Divider color="#E5E0E6" opacity="1" margin="30px 0" />;
}

export function MaterialCard({ children }) {
  return (
    <Box
      width="100%"
      padding="0 20px 20px 20px"
      margin="25px 0"
      backgroundColor="gray.50"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="8px"
    >
      {children}
    </Box>
  );
}

export function NewScrapCheckBox({ children, onChange, disabled }) {
  return (
    <Checkbox
      size="lg"
      fontSize="md"
      colorScheme="green"
      color="gray.800"
      borderColor="gray.700"
      marginTop="5px"
      disabled={disabled}
      onChange={onChange}
    >
      {children}
    </Checkbox>
  );
}

export function ContractCard({
  images,
  id,
  user_type,
  cnpj_nome,
  scrap_info,
  value,
  status,
  buttonRequestCollection,
}) {
  function formatPrice(value) {
    const onlyDigits = value
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
    return maskCurrency(digitsFloat);
  }

  function maskCurrency(valor, locale = "pt-BR", currency = "BRL") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(valor);
  }

  return (
    <Card width="95%" backgroundColor="white" marginTop="10px" padding="15px">
      <HStack>
        <CardHeader padding="0">
          <Image
            objectFit="cover"
            width="130px"
            height="130px"
            borderRadius="4px"
            src={images ? images[0] : defaultImage}
          />
        </CardHeader>
        <CardBody display="flex" justifyContent="space-evenly" padding="0">
          <VStack alignItems="start">
            <Text fontSize="xs" fontWeight="bold" color="gray.500">
              ID
            </Text>
            <Text fontSize="md" color="gray.700">
              {id.split("-")[0]}
            </Text>
          </VStack>
          <VStack alignItems="start">
            <Text fontSize="xs" fontWeight="bold" color="gray.500">
              {user_type === "Industria" ? "COMPRADOR" : "VENDEDOR"}
            </Text>
            <Text fontSize="md" color="gray.700">
              {cnpj_nome}
            </Text>
          </VStack>
          <VStack alignItems="start">
            <Text fontSize="xs" fontWeight="bold" color="gray.500">
              UNIDADE GERADORA
            </Text>
            <Text fontSize="md" color="gray.700">
              {scrap_info ? scrap_info.branch : "- -"}
            </Text>
          </VStack>
          <VStack alignItems="start">
            <Text fontSize="xs" fontWeight="bold" color="gray.500">
              {user_type === "Industria"
                ? "VALOR JÁ RECEBIDO"
                : "VALOR ACUMULADO"}
            </Text>
            <Text fontSize="md" color="gray.700">
              {formatPrice(value)}
            </Text>
          </VStack>
          <VStack alignItems="start">
            <Text fontSize="xs" fontWeight="bold" color="gray.500">
              STATUS
            </Text>
            <AdminBadge
              colorScheme="green"
              textColor="green.800"
              icon={HiCheckCircle}
              iconColor="green.500"
            >
              {status}
            </AdminBadge>
          </VStack>
        </CardBody>
        <CardFooter padding="0">
          {user_type === "Industria" ? (
            status === "Ativo" ? (
              <OutlineButtonComponent onClick={buttonRequestCollection}>
                Solicitar Coleta
              </OutlineButtonComponent>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </CardFooter>
      </HStack>
    </Card>
  );
}

export function CollectionCard({
  id,
  user_type,
  sucateiro_cnpj_nome,
  industria_cnpj_nome,
  total_weight,
  valor_coleta,
  status,
  coleta_date,
  buttonScheduleCollection,
  buttonSendWeight,
  buttonPayCollection,
}) {
  function formatPrice(value) {
    const onlyDigits = value
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
    return maskCurrency(digitsFloat);
  }

  function maskCurrency(valor, locale = "pt-BR", currency = "BRL") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(valor);
  }
  return (
    <Card width="95%" backgroundColor="white" marginTop="10px" padding="15px">
      <HStack>
        <CardBody padding="0">
          <HStack width="95%" justifyContent="space-between">
            <VStack width="80px" alignItems="start">
              <Text fontSize="xs" fontWeight="bold" color="gray.500">
                ID DA COLETA
              </Text>
              <Text fontSize="sm" color="gray.700">
                {id.split("-")[0]}
              </Text>
            </VStack>
            <VStack width="100px" alignItems="start">
              {user_type === "Industria" ? (
                <>
                  <Text fontSize="xs" fontWeight="bold" color="gray.500">
                    COMPRADOR
                  </Text>
                  <Text fontSize="sm" color="gray.700">
                    {sucateiro_cnpj_nome}
                  </Text>
                </>
              ) : (
                <>
                  <Text fontSize="xs" fontWeight="bold" color="gray.500">
                    VENDEDOR
                  </Text>
                  <Text fontSize="sm" color="gray.700">
                    {industria_cnpj_nome}
                  </Text>
                </>
              )}
            </VStack>
            {coleta_date ? (
              <VStack width="100px" height="65px" alignItems="end">
                <Box></Box>
                <Text fontSize="xs" fontWeight="bold" color="gray.500">
                  DATA DA COLETA
                </Text>
                <VStack gap="0">
                  <Text fontSize="sm" color="gray.700">
                    {coleta_date.day}
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {coleta_date.period}
                  </Text>
                </VStack>
              </VStack>
            ) : (
              <VStack width="100px" alignItems="end">
                <Text fontSize="xs" fontWeight="bold" color="gray.500">
                  DATA DA COLETA
                </Text>
                <Text fontSize="sm" color="gray.700">
                  - - -
                </Text>
              </VStack>
            )}
            <VStack width="100px" alignItems="end">
              <HStack gap="3px">
                <Text fontSize="xs" fontWeight="bold" color="gray.500">
                  QUANTIDADE
                </Text>
                <AdminTooltip text="A quantidade é informada após a pesagem da coleta.">
                  <Icon as={HiInformationCircle} color="blue.500" />
                </AdminTooltip>
              </HStack>

              {total_weight ? (
                <Text fontSize="sm" color="gray.700">
                  {total_weight} kg
                </Text>
              ) : (
                <Text fontSize="sm" color="gray.700">
                  - - -
                </Text>
              )}
            </VStack>
            <VStack width="80px" alignItems="end">
              <HStack gap="3px">
                <Text fontSize="xs" fontWeight="bold" color="gray.500">
                  VALOR
                </Text>
                <AdminTooltip text="O valor é calculado com base no peso da coleta e no preço acordado.">
                  <Icon as={HiInformationCircle} color="blue.500" />
                </AdminTooltip>
              </HStack>
              {valor_coleta ? (
                <Text fontSize="sm" color="gray.700">
                  {formatPrice(String(valor_coleta * 100))}
                </Text>
              ) : (
                <Text fontSize="sm" color="gray.700">
                  - - -
                </Text>
              )}
            </VStack>
            <VStack width="200px" alignItems="start">
              <Text fontSize="xs" fontWeight="bold" color="gray.500">
                STATUS
              </Text>
              {status === "Pagamento Realizado" ? (
                <AdminBadge
                  colorScheme="green"
                  textColor="green.800"
                  icon={HiCheckCircle}
                  iconColor="green.500"
                >
                  {status}
                </AdminBadge>
              ) : (
                <AdminBadge
                  colorScheme="orange"
                  textColor="orange.800"
                  icon={HiExclamationCircle}
                  iconColor="orange.500"
                >
                  {status}
                </AdminBadge>
              )}
            </VStack>
          </HStack>
        </CardBody>
        {user_type === "Sucateiro" ? (
          <CardFooter width="150px" padding="0">
            {(status === "Aguardando Agendamento" && (
              <OutlineButtonComponent onClick={buttonScheduleCollection}>
                Agendar Coleta
              </OutlineButtonComponent>
            )) ||
              (status === "Aguardando Coleta" && (
                <OutlineButtonComponent onClick={buttonSendWeight}>
                  Enviar Pesagem
                </OutlineButtonComponent>
              )) ||
              (status === "Aguardando Pagamento" && (
                <OutlineButtonComponent onClick={buttonPayCollection}>
                  Pagar Coleta
                </OutlineButtonComponent>
              ))}
          </CardFooter>
        ) : (
          <></>
        )}
      </HStack>
    </Card>
  );
}

export function CardSucateiro({
  location,
  image,
  expiration_timestamp,
  title,
  current_bid,
  onClick,
  total_weight,
}) {
  const timeRemaining = expiration_timestamp * 1000 - Date.now();

  const timeInDays = dayjs.duration(timeRemaining).days();
  const timeInHours = dayjs.duration(timeRemaining).hours();
  const timeInMinutes = dayjs.duration(timeRemaining).minutes();

  function decodePrice(price) {
    return price.replace("R$", "").replace(".", "").replace(",", ".");
  }

  function changeToTonne(weight) {
    return String(Number(weight) / 1000).replace(".", ",");
  }

  return (
    <Card
      width="290px"
      backgroundColor="white"
      padding="15px"
      onClick={onClick}
      border="1px solid"
      borderColor="gray.200"
      cursor="pointer"
      _hover={{
        borderColor: "gray.400",
        boxShadow: "lg",
      }}
    >
      <CardHeader padding="0">
        <VStack position="relative" marginBottom="10px">
          <Image
            objectFit="cover"
            width="100%"
            height="160px"
            borderRadius="4px"
            src={image}
          />
          <Tag
            size="sm"
            backgroundColor="gray.50"
            color="gray.800"
            position="absolute"
            bottom="10px"
            left="10px"
          >
            Encerra em {timeInDays} dias {timeInHours} horas {timeInMinutes}{" "}
            minutos
          </Tag>
        </VStack>
      </CardHeader>
      <CardBody padding="0">
        <Text fontSize="md" fontWeight="600" color="gray.700">
          {title}
        </Text>
        <VStack alignItems="start" margin="5px 0" gap="0">
          {Number(decodePrice(current_bid)) === 0 ? (
            <HStack height="21px"></HStack>
          ) : (
            <HStack gap="5px">
              <>
                <Text fontSize="sm" fontWeight="600" color="gray.500">
                  Maior oferta:
                </Text>
                <Text fontSize="sm" fontWeight="600" color="#059669">
                  {current_bid}
                </Text>
              </>
            </HStack>
          )}
        </VStack>
        {location ? (
          <AdminTooltip
            text={`${location.neighborhood} - ${location.city} - ${location.state}`}
          >
            <HStack maxWidth="100%">
              <Icon as={FiMapPin} boxSize={4} color={brandColorOrange} />
              <Text
                fontSize="sm"
                fontWeight="600"
                color="gray.500"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {location.neighborhood} - {location.city} - {location.state}
              </Text>
            </HStack>
          </AdminTooltip>
        ) : (
          <HStack maxWidth="100%">
            <Icon as={FiMapPin} boxSize={4} color={brandColorOrange} />
            <Text
              fontSize="sm"
              fontWeight="600"
              color="gray.500"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              - -
            </Text>
          </HStack>
        )}
        <Divider color="#E5E0E6" opacity="1" margin="15px 0" />
      </CardBody>
      <CardFooter padding="0">
        <VStack alignItems="start">
          <Text fontSize="sm" fontWeight="bold" color="gray.500">
            QUANTIDADE TOTAL ESTIMADA
          </Text>
          <Text fontSize="2xl" fontWeight="bold" color="gray.700">
            {changeToTonne(total_weight) === "NaN"
              ? "- - -"
              : `${changeToTonne(total_weight)} ton`}
          </Text>
        </VStack>
      </CardFooter>
    </Card>
  );
}

export function AdminTooltip({ children, isDisabled, text }) {
  return (
    <Tooltip
      width="100%"
      backgroundColor="gray.900"
      placement="top-start"
      hasArrow
      label={text}
      isDisabled={isDisabled}
    >
      <Box as="span" display="flex" alignItems="center">
        {children}
      </Box>
    </Tooltip>
  );
}

export function AccordionHeading({ children }) {
  return (
    <Text
      fontSize="md"
      fontWeight="600"
      color={brandColorBlue}
      marginTop="15px"
    >
      {children}
    </Text>
  );
}

export function AccordionText({ children }) {
  return (
    <Text fontSize="md" color="gray.700" marginTop="5px">
      {children}
    </Text>
  );
}

export function OfferAccordion({
  full_dumpster,
  leave_empty_dumpster,
  has_impurities,
  has_scale,
  scale_capacity,
  site_equipment,
  equipment,
  extra_info,
  materials_estimation_industria,
}) {
  const fullDumpsterText =
    (full_dumpster === "Caçamba do comprador" &&
      "Há uma caçamba cheia da sua empresa no local.") ||
    (full_dumpster === "Caçamba do vendedor" &&
      "Há uma caçamba cheia do fornecedor no local.") ||
    (full_dumpster === "Livre" && "O material está livre (sem caçamba).");

  const leaveEmptyDumpsterText =
    leave_empty_dumpster === "Sim"
      ? "É necessário deixar uma caçamba vazia após a coleta."
      : "Não é necessário levar caçamba vazia ao local.";

  const cardTextArray = [fullDumpsterText, leaveEmptyDumpsterText];

  function formatPrice(value) {
    const onlyDigits = value
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
    return maskCurrency(digitsFloat);
  }

  function maskCurrency(valor, locale = "pt-BR", currency = "BRL") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(valor);
  }

  let formattedMaterials = [];

  if (materials_estimation_industria) {
    formattedMaterials = materials_estimation_industria.map((m) => {
      const formattedPrice = formatPrice(String(m.price * 100));
      const nameAndType = m.name_and_type.split(" ");
      return {
        ...m,
        price: formattedPrice,
        name: nameAndType[0],
        type: nameAndType[2],
      };
    });
  }

  return (
    <Accordion
      backgroundColor="white"
      borderBottom="1px solid"
      borderColor="gray.200"
      borderTopColor="transparent"
      borderRadius="8px"
      marginTop="40px"
      allowToggle
    >
      <AccordionItem>
        <AccordionButton>
          <Box
            as="span"
            fontSize="2xl"
            fontWeight="600"
            color={brandColorBlue}
            padding="5px"
            flex="1"
            textAlign="left"
          >
            Instruções para a coleta
          </Box>
          <AccordionIcon fontSize="2xl" />
        </AccordionButton>
        <AccordionPanel>
          {fullDumpsterText && leaveEmptyDumpsterText ? (
            cardTextArray.map((text) => (
              <Box
                key={text}
                width="100%"
                backgroundColor="blue.50"
                display="flex"
                alignItems="center"
                borderRadius="8px"
                marginTop="15px"
                padding="10px"
                gap="10px"
              >
                <Icon boxSize={5} as={FiCheck} color="black" />
                <Text fontSize="lg" fontWeight="600" color="gray.700">
                  {text}
                </Text>
              </Box>
            ))
          ) : (
            <></>
          )}
          {has_impurities ? (
            <>
              <AccordionHeading>Impurezas</AccordionHeading>
              <AccordionText>
                {(has_impurities === "Sim" &&
                  "Os materiais apresentam impurezas.") ||
                  (has_impurities === "Não" &&
                    "Os materiais não apresentam impurezas.") ||
                  (has_impurities === "Pode haver" &&
                    "Os materiais podem apresentar impurezas.")}
              </AccordionText>
            </>
          ) : (
            <></>
          )}
          <AccordionHeading>Balança no local</AccordionHeading>
          <AccordionText>
            {has_scale
              ? `Possui balança de até ${scale_capacity} toneladas`
              : "Não possui balança no local"}
          </AccordionText>
          {site_equipment ? (
            <>
              <AccordionHeading>Equipamentos no local</AccordionHeading>
              <AccordionText>{site_equipment}</AccordionText>
            </>
          ) : (
            <></>
          )}
          <AccordionHeading>Equipamentos necessários</AccordionHeading>
          <AccordionText>{equipment ? equipment : "Nenhum"}</AccordionText>
          {extra_info ? (
            <>
              <AccordionHeading>Observações extras</AccordionHeading>
              <AccordionText>{extra_info}</AccordionText>
            </>
          ) : (
            <></>
          )}
          {formattedMaterials.length ? (
            <>
              <Text
                fontSize="xl"
                fontWeight="600"
                color={brandColorBlue}
                margin="15px 0"
              >
                Materiais
              </Text>
              <TableContainer
                border="1px solid"
                borderColor="gray.200"
                borderRadius="8px"
                padding="15px"
              >
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th
                        fontSize="xs"
                        fontWeight="bold"
                        color="gray.500"
                        padding="0 0 10px 0"
                      >
                        MATERIAL
                      </Th>
                      <Th
                        fontSize="xs"
                        fontWeight="bold"
                        color="gray.500"
                        padding="0 0 10px 0"
                      >
                        TIPO
                      </Th>
                      <Th
                        fontSize="xs"
                        fontWeight="bold"
                        color="gray.500"
                        padding="0 0 10px 0"
                      >
                        QTD. ESTIMADA
                      </Th>
                      <Th
                        fontSize="xs"
                        fontWeight="bold"
                        color="gray.500"
                        padding="0 0 10px 0"
                      >
                        PREÇO POR KG
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {formattedMaterials.map((m, index) => (
                      <Tr key={index}>
                        <Td maxWidth="120px" padding="10px 0">
                          <AdminTooltip text={m.name}>
                            <Text
                              overflow="hidden"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                            >
                              {m.name}
                            </Text>
                          </AdminTooltip>
                        </Td>
                        <Td maxWidth="120px" padding="10px 0">
                          <AdminTooltip text={m.type}>
                            <Text
                              overflow="hidden"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                            >
                              {m.type}
                            </Text>
                          </AdminTooltip>
                        </Td>
                        <Td maxWidth="120px" padding="10px 0">
                          <AdminTooltip text={`${m.quantity}kg`}>
                            <Text
                              overflow="hidden"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                            >
                              {m.quantity}kg
                            </Text>
                          </AdminTooltip>
                        </Td>
                        <Td maxWidth="120px" padding="10px 0">
                          <AdminTooltip text={`${m.price}`}>
                            <Text
                              overflow="hidden"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                            >
                              {m.price}
                            </Text>
                          </AdminTooltip>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <></>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export function MakeOfferDrawer({
  isOpen,
  onClose,
  chosenScrap,
  currentValue,
  toggler,
  setToggler,
  materialsOffer,
  minimumOffer,
  handleMaterialsOffers,
  currentBid,
  onClick,
  buttonMakeOfferIsLoading,
}) {
  const {
    location,
    expiration_timestamp,
    scrapImages,
    title,
    has_impurities,
    has_scale,
    scale_capacity,
    site_equipment,
    equipment,
    extra_info,
  } = chosenScrap;
  const { neighborhood, city, state } = location || {};

  const coverImage = scrapImages ? scrapImages[0] : defaultImage;

  function decodePrice(price) {
    return price.replace("R$", "").replace(".", "").replace(",", ".");
  }
  const timeRemaining = expiration_timestamp * 1000 - Date.now();

  const timeInDays = dayjs.duration(timeRemaining).days();
  const timeInHours = dayjs.duration(timeRemaining).hours();
  const timeInMinutes = dayjs.duration(timeRemaining).minutes();

  return (
    <Drawer
      size={{ sm: "sm", md: "md", lg: "lg" }}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader fontSize="3xl" fontWeight="600" color={brandColorBlue}>
          Faça sua oferta
        </DrawerHeader>
        <DrawerBody>
          <Flex
            backgroundColor={brandColorBlue}
            padding="15px 25px"
            justifyContent="space-between"
            borderTopLeftRadius="8px"
            borderTopRightRadius="8px"
          >
            <VStack>
              <Text fontSize="xs" fontWeight="bold" color="gray.400">
                TEMPO RESTANTE DO LEILÃO
              </Text>
              <Text fontSize="md" fontWeight="600" color="gray.50">
                {timeInDays} dias {timeInHours} horas {timeInMinutes} minutos
              </Text>
            </VStack>
            {Number(decodePrice(currentValue)) === 0 ? (
              <></>
            ) : (
              <>
                <VStack>
                  <HStack>
                    <Text fontSize="xs" fontWeight="bold" color="gray.400">
                      MAIOR OFERTA
                    </Text>
                    <AdminTooltip text="Esse é o valor total da maior oferta até o momento.">
                      <Icon as={HiInformationCircle} color="blue.500" />
                    </AdminTooltip>
                  </HStack>
                  <Text fontSize="xl" fontWeight="600" color="#10B981">
                    {currentValue}
                  </Text>
                </VStack>
              </>
            )}
          </Flex>
          <Box
            padding="25px"
            border="1px solid"
            borderColor="gray.200"
            borderBottomLeftRadius="8px"
            borderBottomRightRadius="8px"
          >
            <HStack>
              <Box
                position="relative"
                cursor="pointer"
                onClick={() => setToggler(!toggler)}
              >
                <Image
                  objectFit="cover"
                  width="160px"
                  height="160px"
                  borderRadius="4px"
                  src={coverImage}
                />
                <Tag
                  size="sm"
                  fontWeight="500"
                  backgroundColor="orange.50"
                  color="orange.600"
                  position="absolute"
                  bottom="5px"
                  left="5px"
                >
                  Acessar galeria de fotos
                </Tag>
              </Box>

              <VStack
                width="calc(100% - 160px)"
                height="160px"
                alignItems="start"
                justifyContent="space-around"
              >
                <Text fontSize="2xl" fontWeight="600" color="gray.700">
                  {title}
                </Text>
                <Text fontSize="md" color="gray.700">
                  {neighborhood}, {city} - {state}
                </Text>
              </VStack>
            </HStack>
            <TableContainer
              border="1px solid"
              borderColor="gray.200"
              borderRadius="8px"
              margin="15px 0"
            >
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th
                      fontSize="xs"
                      fontWeight="bold"
                      color="gray.500"
                      padding="15px"
                    >
                      MATERIAL
                    </Th>
                    <Th
                      fontSize="xs"
                      fontWeight="bold"
                      color="gray.500"
                      padding="15px"
                    >
                      TIPO
                    </Th>
                    <Th
                      fontSize="xs"
                      fontWeight="bold"
                      color="gray.500"
                      padding="15px"
                    >
                      QTD. ESTIMADA
                    </Th>
                    <Th
                      fontSize="xs"
                      fontWeight="bold"
                      color="gray.500"
                      padding="15px"
                    >
                      SUA OFERTA
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {materialsOffer.map((m, index) => (
                    <Tr key={index}>
                      <Td maxWidth="120px" padding="10px 15px">
                        <AdminTooltip text={m.name}>
                          <Text
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                          >
                            {m.name}
                          </Text>
                        </AdminTooltip>
                      </Td>
                      <Td maxWidth="120px" padding="10px 15px">
                        <AdminTooltip text={m.type}>
                          <Text
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                          >
                            {m.type}
                          </Text>
                        </AdminTooltip>
                      </Td>
                      <Td maxWidth="120px" padding="10px 15px">
                        <AdminTooltip text={`${m.quantity}kg`}>
                          <Text
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                          >
                            {m.quantity}kg
                          </Text>
                        </AdminTooltip>
                      </Td>
                      <Td padding="10px 15px" maxWidth="170px">
                        <HStack>
                          <SessionInput
                            name="price"
                            value={m.price}
                            onChange={(e) =>
                              handleMaterialsOffers(index, e.target)
                            }
                            placeholder="R$/kg"
                          />
                          <Text>/Kg</Text>
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <HStack justifyContent="space-between">
              <VStack gap="0">
                <Text fontSize="md" fontWeight="600" color={brandColorBlue}>
                  Valor total da sua oferta:
                </Text>
                {Number(decodePrice(currentValue)) === 0 ? (
                  <></>
                ) : (
                  <Text fontSize="xs" color="gray.500">
                    Oferta mínima de {minimumOffer}
                  </Text>
                )}
              </VStack>

              <Text fontSize="3xl" fontWeight="bold" color="#059669">
                {Number(decodePrice(currentBid)) === 0 ? "- - -" : currentBid}
              </Text>
            </HStack>
            <AlertComponent
              title="Atenção"
              description="O valor total da sua oferta é apenas uma estimativa com base nas quantidades aproximadas dos materiais. Os valores reais serão calculados com base nas pesagens de cada coleta a ser realizada."
              status="info"
            />
          </Box>
          <OfferAccordion
            has_impurities={has_impurities}
            has_scale={has_scale}
            scale_capacity={scale_capacity}
            site_equipment={site_equipment}
            equipment={equipment}
            extra_info={extra_info}
          />
        </DrawerBody>

        <DrawerFooter>
          <SessionButton onClick={onClick} isLoading={buttonMakeOfferIsLoading}>
            Fazer oferta
          </SessionButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export function OutlineIconButton({ onClick, isDisabled }) {
  return (
    <IconButton
      size="lg"
      variant="outline"
      color={brandColorBlue}
      border="1px solid"
      borderColor={brandColorBlue}
      _focus={{
        outline: "3px solid",
        outlineColor: "blue.50",
      }}
      _disabled={{
        backgroundColor: "gray.400",
        color: "gray.400",
        cursor: "not-allowed",
      }}
      onClick={onClick}
      isDisabled={isDisabled}
      icon={<FiTrash2 />}
    />
  );
}

export function DayCard({
  day,
  chosenDate,
  setChosenDate,
  dateOptions,
  selectedDate,
}) {
  const formattedDay = dayjs(day).format("YYYY-MM-DD");
  const dateActive = chosenDate === formattedDay;
  const dateSelected = dateOptions[formattedDay].selected;
  let tagText = "";
  if (dateSelected) {
    tagText =
      dateOptions[formattedDay].period.length === 1
        ? dateOptions[formattedDay].period
        : "Manhã, Tarde";
  } else if (selectedDate) {
    tagText = selectedDate.period;
  }
  return (
    <Card
      width="110px"
      height="155px"
      variant="outline"
      border="1px solid"
      borderColor={
        (dateActive && "orange.500") ||
        dateSelected ||
        (selectedDate.day === day && brandColorBlue) ||
        (!dateSelected && "gray.300")
      }
      outline={dateActive ? "4px solid" : "0"}
      outlineColor={dateActive ? "orange.50" : ""}
      padding="15px"
      textAlign="center"
      cursor="pointer"
      onClick={() => setChosenDate(formattedDay)}
    >
      <CardHeader
        fontSize="md"
        fontWeight="600"
        color={
          (dateActive && "orange.400") ||
          dateSelected ||
          (selectedDate.day === day && brandColorBlue) ||
          (!dateSelected && "gray.500")
        }
        padding="0"
      >
        {dayjs(day).format("ddd").toUpperCase()}
      </CardHeader>
      <CardBody padding="0">
        <Text
          fontSize="3xl"
          fontWeight="bold"
          color={
            (dateActive && "orange.500") ||
            dateSelected ||
            (selectedDate.day === day && brandColorBlue) ||
            (!dateSelected && "gray.600")
          }
        >
          {dayjs(day).format("DD").toUpperCase()}
        </Text>
        <Text
          fontSize="xs"
          fontWeight="600"
          color={
            (dateActive && "orange.400") ||
            dateSelected ||
            (selectedDate.day === day && brandColorBlue) ||
            (!dateSelected && "gray.500")
          }
        >
          {dayjs(day).format("MMMM").toUpperCase()}
        </Text>
      </CardBody>
      <CardFooter padding="0" display="flex" justifyContent="center">
        {dateSelected || selectedDate.day === day ? (
          <Tag
            size="sm"
            variant="solid"
            borderRadius="16px"
            backgroundColor={dateActive ? "orange.500" : brandColorBlue}
            padding="2px"
          >
            <Text fontSize="xs" fontWeight="500" color="white">
              {tagText}
            </Text>
          </Tag>
        ) : (
          <></>
        )}
      </CardFooter>
    </Card>
  );
}

export function PeriodCard({
  periodKey,
  chosenDate,
  dateOptions,
  setDateOptions,
  selectedDate,
  setselectedDate,
}) {
  const periodIsSelected = dateOptions
    ? dateOptions[chosenDate].period.includes(periodKey)
    : selectedDate.day === chosenDate && selectedDate.period === periodKey;

  function handlePeriod() {
    if (dateOptions) {
      if (!periodIsSelected) {
        const periodArray = dateOptions[chosenDate].period;
        periodArray.push(periodKey);
        setDateOptions({
          ...dateOptions,
          [chosenDate]: {
            period: periodArray,
            selected: true,
          },
        });
      } else {
        const periodArray = dateOptions[chosenDate].period;
        periodArray.splice(periodArray.indexOf(periodKey), 1);
        setDateOptions({
          ...dateOptions,
          [chosenDate]: {
            period: periodArray,
            selected: periodArray.length ? true : false,
          },
        });
      }
    } else {
      setselectedDate({
        day: chosenDate,
        period: periodKey,
      });
    }
  }
  let buttonText;
  if (periodKey === "Manhã") buttonText = "Manhã (08:00 - 12:00)";
  else buttonText = "Tarde (13:00 - 18:00)";

  return (
    <Box
      width="50%"
      backgroundColor={periodIsSelected ? "orange.500" : "white"}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="4px"
      padding="10px 0"
      textAlign="center"
      cursor="pointer"
      onClick={handlePeriod}
    >
      <Text
        fontSize="md"
        fontWeight="500"
        color={periodIsSelected ? "white" : brandColorBlue}
      >
        {buttonText}
      </Text>
    </Box>
  );
}

export function ScheduleCollectionDrawer({
  isOpen,
  onClose,
  chosenCollection,
  chosenDate,
  setChosenDate,
  selectedDate,
  setselectedDate,
  scheduleCollection,
  buttonDisabled,
  buttonLoading,
}) {
  const {
    industria_cnpj_nome,
    scrap_info,
    date_options,
    full_dumpster,
    leave_empty_dumpster,
    materials_estimation_industria,
  } = chosenCollection;
  const {
    location,
    has_scale,
    scale_capacity,
    site_equipment,
    equipment,
    extra_info,
  } = scrap_info || {};
  const { street, number, complement, neighborhood, city, state } =
    location || {};

  function compareDates(a, b) {
    const prevTimestamp = dayjs(a[0]).unix();
    const currTimestamp = dayjs(b[0]).unix();
    if (currTimestamp < prevTimestamp) {
      return 1;
    } else {
      return -1;
    }
  }

  return (
    <Drawer
      size={{ sm: "sm", md: "md", lg: "lg" }}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom="0">
          <Text fontSize="3xl" fontWeight="600" color={brandColorBlue}>
            Agendar coleta
          </Text>
          {industria_cnpj_nome && location ? (
            <>
              <Text fontSize="md" fontWeight="500" color="gray.500">
                {industria_cnpj_nome}
              </Text>
              <Text fontSize="md" fontWeight="500" color="gray.500">
                {street} {number} {complement}, {neighborhood}, {city} - {state}
              </Text>
            </>
          ) : (
            <></>
          )}
        </DrawerHeader>
        <DrawerBody>
          <Text
            fontSize="2xl"
            fontWeight="600"
            color={brandColorBlue}
            marginTop="25px"
          >
            Agendamento
          </Text>
          <Text fontSize="md" color={brandColorBlue}>
            Selecione a data e horário para confirmar o agendamento da coleta
          </Text>
          <HStack justifyContent="start" marginTop="15px">
            {date_options &&
              Object.entries(date_options)
                .sort(compareDates)
                .map((d) => (
                  <DayCard
                    key={d[0]}
                    day={d[0]}
                    chosenDate={chosenDate}
                    setChosenDate={setChosenDate}
                    dateOptions={date_options}
                    selectedDate={selectedDate}
                  />
                ))}
          </HStack>
          {chosenDate ? (
            <HStack marginTop="25px">
              {date_options[chosenDate].period.map((p) => (
                <PeriodCard
                  key={p}
                  periodKey={p}
                  chosenDate={chosenDate}
                  selectedDate={selectedDate}
                  setselectedDate={setselectedDate}
                />
              ))}
            </HStack>
          ) : (
            <></>
          )}
          <PageDivider />
          <Text fontSize="md" color={brandColorBlue} textAlign="center">
            Não consegue agendar em nenhum dos horários?{" "}
            <Link
              size="md"
              fontWeight="600"
              color={brandColorBlue}
              href="https://api.whatsapp.com/send?phone=5521970623292"
              isExternal
            >
              Fale com a Meetal
            </Link>
          </Text>
          <OfferAccordion
            full_dumpster={full_dumpster}
            leave_empty_dumpster={leave_empty_dumpster}
            has_scale={has_scale}
            scale_capacity={scale_capacity}
            site_equipment={site_equipment}
            equipment={equipment}
            extra_info={extra_info}
            materials_estimation_industria={materials_estimation_industria}
          />
        </DrawerBody>

        <DrawerFooter>
          <SessionButton
            onClick={scheduleCollection}
            isDisabled={buttonDisabled}
            isLoading={buttonLoading}
          >
            Confirmar agendamento
          </SessionButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export function SendWeightDrawer({
  isOpen,
  onClose,
  chosenCollection,
  handleMaterials,
  currentWeight,
  currentPrice,
  image,
  setImage,
  finishCollection,
  buttonLoading,
}) {
  const { industria_cnpj_nome, scrap_info, materials_estimation_industria } =
    chosenCollection;
  const { location } = scrap_info || {};
  const { street, number, complement, neighborhood, city, state } =
    location || {};

  function formatPrice(value) {
    const onlyDigits = value
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
    return maskCurrency(digitsFloat);
  }

  function maskCurrency(valor, locale = "pt-BR", currency = "BRL") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(valor);
  }

  let formattedMaterials = [];

  if (materials_estimation_industria) {
    formattedMaterials = materials_estimation_industria.map((m) => {
      const formattedPrice = formatPrice(String(m.price * 100));
      const nameAndType = m.name_and_type.split(" ");
      return {
        ...m,
        price: formattedPrice,
        name: nameAndType[0],
        type: nameAndType[2],
      };
    });
  }

  return (
    <Drawer
      size={{ sm: "sm", md: "md", lg: "lg" }}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom="0">
          <Text fontSize="3xl" fontWeight="600" color={brandColorBlue}>
            Enviar pesagem
          </Text>
          {industria_cnpj_nome && location ? (
            <>
              <Text fontSize="md" fontWeight="500" color="gray.500">
                {industria_cnpj_nome}
              </Text>
              <Text fontSize="md" fontWeight="500" color="gray.500">
                {street} {number} {complement}, {neighborhood}, {city} - {state}
              </Text>
            </>
          ) : (
            <></>
          )}
        </DrawerHeader>
        <DrawerBody>
          <Text
            fontSize="2xl"
            fontWeight="600"
            color={brandColorBlue}
            marginTop="25px"
          >
            Quantidades pesadas
          </Text>
          <Text fontSize="sm" color="gray.700">
            Informe as quantidades pesadas e as impurezas dos materiais.
          </Text>
          <VStack>
            {formattedMaterials.map((m, index) => (
              <Box
                key={m.name}
                width="100%"
                backgroundColor="gray.50"
                border="1px solid"
                borderColor="gray.200"
                borderRadius="8px"
                marginTop="15px"
                padding="15px"
                display="flex"
                justifyContent="space-between"
              >
                <VStack width="50%" alignItems="start">
                  <Text fontSize="2xl" fontWeight="600" color="gray.700">
                    {m.name} - {m.type}
                  </Text>
                  <Text
                    fontSize="md"
                    fontWeight="600"
                    color={brandColorBlue}
                    marginTop="15px"
                  >
                    Preço por quilo:
                  </Text>
                  <Text fontSize="md" color="gray.700">
                    {m.price} /kg
                  </Text>
                  <Text
                    fontSize="md"
                    fontWeight="600"
                    color={brandColorBlue}
                    marginTop="15px"
                  >
                    Qtd. informada pelo vendedor:
                  </Text>
                  <Text fontSize="md" color="gray.700">
                    {m.quantity}kg
                  </Text>
                </VStack>
                <VStack width="50%" alignItems="start" paddingLeft="120px">
                  <Text fontSize="md" fontWeight="600" color={brandColorBlue}>
                    Qtd. pesada (kg)
                  </Text>
                  <InputWithAddonComponent
                    size="lg"
                    placeholder="0kg"
                    type="number"
                    defaultValue={m.quantity}
                    name="quantity"
                    onChange={(e) => handleMaterials(index, e.target)}
                    rightAddon="Kg"
                  />
                  <Text
                    fontSize="md"
                    fontWeight="600"
                    color={brandColorBlue}
                    marginTop="15px"
                  >
                    Impurezas (opcional)
                  </Text>
                  <InputWithAddonComponent
                    size="lg"
                    placeholder="0%"
                    defaultValue="0"
                    type="number"
                    name="impurities"
                    onChange={(e) => handleMaterials(index, e.target)}
                    rightAddon="%"
                  />
                </VStack>
              </Box>
            ))}
          </VStack>
          <VStack alignItems="start" gap="0">
            <HStack alignItems="center" marginTop="25px">
              <Text fontSize="xl" fontWeight="600" color={brandColorBlue}>
                Peso final
              </Text>
              <AdminTooltip text="Esse é o peso total dos materiais ">
                <Icon as={HiInformationCircle} color="blue.500" />
              </AdminTooltip>
            </HStack>
            <Text fontSize="md" color="gray.700">
              {currentWeight} kg
            </Text>
          </VStack>
          <VStack alignItems="start" gap="0">
            <HStack alignItems="center" marginTop="25px">
              <Text fontSize="xl" fontWeight="600" color={brandColorBlue}>
                Valor a pagar
              </Text>
              <AdminTooltip text="Esse é o valor a ser pago pela coleta, já descontando as impurezas">
                <Icon as={HiInformationCircle} color="blue.500" />
              </AdminTooltip>
            </HStack>
            <Text fontSize="xl" fontWeight="600" color="#059669">
              {formatPrice(currentPrice)}
            </Text>
          </VStack>
          <VStack alignItems="start" marginTop="40px">
            <Text fontSize="lg" fontWeight="600" color={brandColorBlue}>
              Faça upload do ticket de pesagem
            </Text>
            <InputFileComponent
              onChange={(e) => setImage(e.target.files[0])}
              image={image}
              setImage={setImage}
            />
          </VStack>
          <AlertComponent
            title="Atenção"
            description="O vendedor poderá contestar a pesagem caso sejam identificadas possíveis inconsistências nas quantidades."
            status="info"
          />
        </DrawerBody>

        <DrawerFooter>
          <SessionButton onClick={finishCollection} isLoading={buttonLoading}>
            Enviar pesagem
          </SessionButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export function CreateAgentDrawer({
  isOpen,
  onClose,
  formData,
  handleInputChange,
  agentTypes,
  setAgentTypes,
  handleInputLocationChange,
  saveAgent,
  buttonLoading,
}) {
  const {
    nickname,
    entity_type,
    name,
    brand_name,
    cpf,
    cnpj,
    rg,
    state_registration,
    email,
    receipt_email,
    whatsapp,
    phone,
    website,
    observations,
    location,
    bank_account,
  } = formData;

  const { cep, state, city, neighborhood, street, number, complement } =
    location;

  const {
    payment_type,
    brand_name_or_name,
    cnpj_or_cpf,
    pix_key,
    bank,
    bank_branch,
    account,
  } = bank_account;

  const agentTypeOptions = [
    { label: "Fornecedor", value: "Fornecedor" },
    { label: "Cliente", value: "Cliente" },
    { label: "Transportador", value: "Transportador" },
    { label: "Motorista", value: "Motorista" },
    { label: "Colaborador", value: "Colaborador" },
  ];

  return (
    <Drawer
      size={{ sm: "sm", md: "md", lg: "lg" }}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom="0">
          <Text fontSize="3xl" fontWeight="600" color={brandColorBlue}>
            Novo agente
          </Text>
          <Text fontSize="sm" fontWeight="400" color="gray.700">
            Preencha as informações do novo agente.
          </Text>
        </DrawerHeader>
        <DrawerBody>
          <FormControl>
            <SessionFormLabel>Apelido</SessionFormLabel>
            <SessionInput
              placeholder="Digite aqui o apelido"
              name="nickname"
              value={nickname}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Tipo</SessionFormLabel>
            <Select
              options={agentTypeOptions}
              isMulti
              value={agentTypes}
              onChange={setAgentTypes}
              styles={{
                control: (styles) => ({
                  ...styles,
                  backgroundColor: "white",
                  width: "100%",
                }),
              }}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Entidade</SessionFormLabel>
            <RadioGroup
              colorScheme="green"
              value={entity_type}
              onChange={(entityType) => {
                const e = {
                  target: {
                    name: "entity_type",
                    value: entityType,
                  },
                };
                handleInputChange(e);
              }}
            >
              <VStack alignItems="start">
                <Radio value="PJ">PJ - Pessoa Jurídica</Radio>
                <Radio value="PF">PF - Pessoa Física</Radio>
              </VStack>
            </RadioGroup>
          </FormControl>
          {(entity_type === "PJ" && (
            <>
              <FormControl>
                <SessionFormLabel>Razão social</SessionFormLabel>
                <SessionInput
                  placeholder="Digite aqui a razão social"
                  name="brand_name"
                  value={brand_name}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl>
                <SessionFormLabel>CNPJ</SessionFormLabel>
                <SessionInput
                  placeholder="Digite aqui o CNPJ"
                  name="cnpj"
                  value={cnpj}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl>
                <SessionFormLabel>Inscrição estadual</SessionFormLabel>
                <SessionInput
                  placeholder="Digite aqui a inscrição estadual"
                  name="state_registration"
                  value={state_registration}
                  onChange={handleInputChange}
                />
              </FormControl>
            </>
          )) ||
            (entity_type === "PF" && (
              <>
                <FormControl>
                  <SessionFormLabel>Nome completo</SessionFormLabel>
                  <SessionInput
                    placeholder="Digite aqui o nome completo"
                    name="name"
                    value={name}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <SessionFormLabel>CPF</SessionFormLabel>
                  <SessionInput
                    placeholder="Digite aqui o CPF"
                    name="cpf"
                    value={cpf}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <SessionFormLabel>RG</SessionFormLabel>
                  <SessionInput
                    placeholder="Digite aqui o RG"
                    name="rg"
                    value={rg}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </>
            ))}
          <FormControl>
            <SessionFormLabel>Email</SessionFormLabel>
            <SessionInput
              placeholder="Digite aqui o email"
              name="email"
              value={email}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>
              Email para recibo ou nota fiscal
            </SessionFormLabel>
            <SessionInput
              placeholder="Digite aqui o email para recibo"
              name="receipt_email"
              value={receipt_email}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Telefone</SessionFormLabel>
            <SessionInput
              placeholder="Digite aqui o telefone"
              name="phone"
              value={phone}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Whatsapp</SessionFormLabel>
            <SessionInput
              placeholder="Digite aqui o whatsapp"
              name="whatsapp"
              value={whatsapp}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>CEP</SessionFormLabel>
            <SessionInput
              placeholder="Digite aqui o CEP"
              name="cep"
              value={cep}
              onChange={handleInputLocationChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Estado</SessionFormLabel>
            <SessionInput
              placeholder="Digite aqui o estado"
              name="state"
              value={state}
              onChange={handleInputLocationChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Cidade</SessionFormLabel>
            <SessionInput
              placeholder="Digite aqui a Cidade"
              name="city"
              value={city}
              onChange={handleInputLocationChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Bairro</SessionFormLabel>
            <SessionInput
              placeholder="Digite aqui o bairro"
              name="neighborhood"
              value={neighborhood}
              onChange={handleInputLocationChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Rua</SessionFormLabel>
            <SessionInput
              placeholder="Digite aqui a rua"
              name="street"
              value={street}
              onChange={handleInputLocationChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Número</SessionFormLabel>
            <SessionInput
              placeholder="Digite aqui o número"
              name="number"
              value={number}
              onChange={handleInputLocationChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Complemento</SessionFormLabel>
            <SessionInput
              placeholder="Digite aqui o complemento"
              name="complement"
              value={complement}
              onChange={handleInputLocationChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Website</SessionFormLabel>
            <SessionInput
              placeholder="Digite aqui o website"
              name="website"
              value={website}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Observações</SessionFormLabel>
            <TextareaComponent
              placeholder="Digite aqui sua observação..."
              name="observations"
              value={observations}
              onChange={handleInputChange}
            />
          </FormControl>
        </DrawerBody>
        <DrawerFooter>
          <SessionButton onClick={saveAgent} isLoading={buttonLoading}>
            Salvar agente
          </SessionButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export function CreateEquipmentDrawer({
  isOpen,
  onClose,
  formData,
  setFormData,
  handleInputChange,
  saveEquipment,
  buttonLoading,
}) {
  const {
    equipment_type,
    vehicle_type,
    model,
    chassis,
    mileage,
    dumpster_type,
    volume,
    equipment_code,
    manufacturer,
    year_of_manufacture,
    color,
  } = formData;

  const equipmentTypeOptions = [
    { name: "Máquina", value: "Máquina" },
    { name: "Veículo", value: "Veículo" },
    { name: "Caçamba", value: "Caçamba" },
  ];

  const vehicleTypeOptions = [
    { name: "Caminhão", value: "Caminhão" },
    { name: "Carreta", value: "Carreta" },
    { name: "Julieta", value: "Julieta" },
  ];

  const dumpsterTypeOptions = [
    { name: "Alta", value: "Alta" },
    { name: "Baixa", value: "Baixa" },
    { name: "Plataforma", value: "Plataforma" },
    { name: "Poliguindaste", value: "Poliguindaste" },
  ];

  return (
    <Drawer
      size={{ sm: "sm", md: "md", lg: "lg" }}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom="0">
          <Text fontSize="3xl" fontWeight="600" color={brandColorBlue}>
            Novo equipamento
          </Text>
          <Text fontSize="sm" fontWeight="400" color="gray.700">
            Preencha as informações do novo equipamento.
          </Text>
        </DrawerHeader>
        <DrawerBody>
          <SelectComponent
            name="equipment_type"
            value={equipment_type}
            onChange={(e) => {
              handleInputChange(e);
              setFormData({
                equipment_type: e.target.value,
                vehicle_type: "",
                model: "",
                chassis: "",
                mileage: "",
                dumpster_type: "",
                volume: "",
                equipment_code: "",
                manufacturer: "",
                year_of_manufacture: "",
                color: "",
              });
            }}
            options={equipmentTypeOptions}
          />
          {equipment_type === "Máquina" && (
            <SessionInput
              placeholder="Código do equipamento"
              name="equipment_code"
              value={equipment_code}
              onChange={handleInputChange}
            />
          )}
          {equipment_type === "Veículo" && (
            <>
              <SelectComponent
                placeholder="Selecione o tipo do veículo"
                name="vehicle_type"
                value={vehicle_type}
                onChange={handleInputChange}
                options={vehicleTypeOptions}
              />
              <SessionInput
                placeholder="Placa do veículo"
                name="equipment_code"
                value={equipment_code}
                onChange={handleInputChange}
              />
              <SessionInput
                placeholder="Modelo do veículo"
                name="model"
                value={model}
                onChange={handleInputChange}
              />
              <SessionInput
                placeholder="Chassi"
                name="chassis"
                value={chassis}
                onChange={handleInputChange}
              />
              <SessionInput
                placeholder="KM"
                name="mileage"
                value={mileage}
                onChange={handleInputChange}
              />
            </>
          )}
          {equipment_type === "Caçamba" && (
            <>
              <SelectComponent
                placeholder="Selecione o tipo da caçamba"
                name="dumpster_type"
                value={dumpster_type}
                onChange={handleInputChange}
                options={dumpsterTypeOptions}
              />
              <SessionInput
                placeholder="Código do equipamento"
                name="equipment_code"
                value={equipment_code}
                onChange={handleInputChange}
              />
              <SessionInput
                placeholder="Volume (m³)"
                name="volume"
                value={volume}
                onChange={handleInputChange}
              />
            </>
          )}
          <SessionInput
            placeholder="Fabricante"
            name="manufacturer"
            value={manufacturer}
            onChange={handleInputChange}
          />
          <SessionInput
            placeholder="Ano de fabricação"
            name="year_of_manufacture"
            value={year_of_manufacture}
            onChange={handleInputChange}
          />
          <SessionInput
            placeholder="Cor"
            name="color"
            value={color}
            onChange={handleInputChange}
          />
        </DrawerBody>
        <DrawerFooter>
          <SessionButton onClick={saveEquipment} isLoading={buttonLoading}>
            Salvar agente
          </SessionButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export function CreateMaterialDrawer({
  drawerIsOpen,
  drawerOnClose,
  formData,
  handleInputChange,
  userLabels,
  modalOnOpen,
  saveAgent,
  buttonLoading,
}) {
  const { material_name, label_id, measure_unit, cst, ncm, observations } =
    formData;

  const measureUnitOptions = [
    { name: "Tonelada", value: "Tonelada" },
    { name: "Kg", value: "Kg" },
    { name: "Litros", value: "Litros" },
    { name: "m", value: "m" },
    { name: "m²", value: "m²" },
    { name: "m³", value: "m³" },
  ];

  return (
    <Drawer
      size={{ sm: "sm", md: "md", lg: "lg" }}
      isOpen={drawerIsOpen}
      placement="right"
      onClose={drawerOnClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom="0">
          <Text fontSize="3xl" fontWeight="600" color={brandColorBlue}>
            Novo material
          </Text>
          <Text fontSize="sm" fontWeight="400" color="gray.700">
            Preencha as informações para adicionar o material.
          </Text>
        </DrawerHeader>
        <DrawerBody>
          <FormControl>
            <SessionFormLabel>Nome do material</SessionFormLabel>
            <SessionInput
              placeholder="Digite o nome do material"
              name="material_name"
              value={material_name}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Grupo do material</SessionFormLabel>
            <SelectComponent
              placeholder="Selecione um grupo"
              name="label_id"
              value={label_id}
              onChange={handleInputChange}
              options={userLabels}
            />
          </FormControl>
          <OutlineButtonComponent
            size="sm"
            marginTop="10px"
            onClick={modalOnOpen}
            icon={<FiPlus />}
          >
            Adicionar novo grupo
          </OutlineButtonComponent>
          <FormControl>
            <SessionFormLabel>Unidade de medida</SessionFormLabel>
            <SelectComponent
              placeholder="Selecione a unidade de medida"
              name="measure_unit"
              value={measure_unit}
              onChange={handleInputChange}
              options={measureUnitOptions}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>
              CST (Código da Situação Tributária)
            </SessionFormLabel>
            <SessionInput
              placeholder="Digite o CST"
              name="cst"
              value={cst}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>
              NCM (Nomenclatura Comum do Mercosul)
            </SessionFormLabel>
            <SessionInput
              placeholder="Digite a NCM"
              name="ncm"
              value={ncm}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Observações</SessionFormLabel>
            <TextareaComponent
              placeholder="Digite aqui sua observação..."
              name="observations"
              value={observations}
              onChange={handleInputChange}
            />
          </FormControl>
        </DrawerBody>
        <DrawerFooter>
          <SessionButton onClick={saveAgent} isLoading={buttonLoading}>
            Salvar agente
          </SessionButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

function EditableControls({ label, sendEditedLabel, setEditedMaterialLabel }) {
  function handleEditClick() {
    const labelClicked = {
      label_id: label.value,
      label_name: label.name,
    };
    setEditedMaterialLabel(labelClicked);
  }

  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup size="sm">
      <IconButton
        size="sm"
        backgroundColor="gray.100"
        color={brandColorBlue}
        _hover={{
          backgroundColor: "gray.300",
        }}
        icon={<FiCheck />}
        {...getSubmitButtonProps({
          onClick: sendEditedLabel,
        })}
      />
      <IconButton
        size="sm"
        backgroundColor="gray.100"
        color={brandColorBlue}
        _hover={{
          backgroundColor: "gray.300",
        }}
        icon={<FiX />}
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : (
    <IconButton
      size="sm"
      backgroundColor="gray.100"
      color={brandColorBlue}
      _hover={{
        backgroundColor: "gray.300",
      }}
      icon={<FiEdit />}
      {...getEditButtonProps({
        onClick: handleEditClick,
      })}
    />
  );
}

export function LabelModal({
  isOpen,
  onClose,
  sendEditedLabel,
  userLabels,
  editedMaterialLabel,
  setEditedMaterialLabel,
  newMaterialLabel,
  setNewMaterialLabel,
  closeButtonOnClick,
  closeButtonText,
  acceptButtonOnClick,
  acceptButtonText,
}) {
  function handleEditOnChange(e) {
    const label = {
      ...editedMaterialLabel,
      label_name: e.target.value,
    };

    setEditedMaterialLabel(label);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent width="320px">
        <ModalHeader fontSize="md" fontWeight="bold" color="black">
          Adicionar novo grupo
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDirection="column" gap="15px">
          {userLabels.map((l) => (
            <Editable
              key={l.value}
              defaultValue={l.name}
              isPreviewFocusable={false}
              submitOnBlur={false}
            >
              <Flex alignItems="center" gap="10px">
                <EditablePreview />
                <EditableInput size="sm" onChange={handleEditOnChange} />
                <EditableControls
                  label={l}
                  sendEditedLabel={sendEditedLabel}
                  setEditedMaterialLabel={setEditedMaterialLabel}
                />
              </Flex>
            </Editable>
          ))}
          <SessionInput
            placeholder="Digite o novo grupo..."
            maxlength={20}
            value={newMaterialLabel}
            onChange={(e) => {
              setNewMaterialLabel(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter gap="10px">
          <Button width="100%" variant="outline" onClick={closeButtonOnClick}>
            {closeButtonText}
          </Button>
          <Button
            width="100%"
            backgroundColor={brandColorBlue}
            color="white"
            _hover={{
              backgroundColor: "gray.900",
            }}
            onClick={acceptButtonOnClick}
          >
            {acceptButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function CreateCollectionDrawer({
  isOpen,
  onClose,
  formData,
  setFormData,
  selectListItem,
  searchStates,
  handleSearchInput,
  handleInputChange,
  saveTransport,
  buttonLoading,
}) {
  const {
    collection_purpose,
    estimated_timestamp,
    freight_type,
    driver,
    truck,
    observations,
  } = formData;

  const collectionPurposeOptions = [
    { name: "Levar", value: "Levar" },
    { name: "Buscar", value: "Buscar" },
    { name: "Trocar", value: "Trocar" },
  ];

  const collectionFreightTypeOptions = [
    { name: "Empresa", value: "Empresa" },
    { name: "Fornecedor", value: "Fornecedor" },
  ];

  return (
    <Drawer
      size={{ sm: "sm", md: "md", lg: "lg" }}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom="0">
          <Text fontSize="3xl" fontWeight="600" color={brandColorBlue}>
            Adicionar coleta
          </Text>
          <Text fontSize="sm" fontWeight="400" color="gray.700">
            Preencha as informações da nova coleta.
          </Text>
        </DrawerHeader>
        <DrawerBody>
          <FormControl>
            <SessionFormLabel>Objetivo da coleta</SessionFormLabel>
            <SelectComponent
              placeholder="Selecione o objetivo"
              name="collection_purpose"
              value={collection_purpose}
              onChange={(e) => {
                handleInputChange(e, formData, setFormData);
              }}
              options={collectionPurposeOptions}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Fornecedor</SessionFormLabel>
            <SearchComponent
              name="Fornecedor"
              parameters={{ formDataName: "supplier", idName: "agent_id" }}
              selectListItem={selectListItem}
              formData={formData}
              setFormData={setFormData}
              searchState={searchStates["Fornecedor"]}
              handleSearchInput={handleSearchInput}
              placeholder="Pesquise e selecione o fornecedor..."
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Data e hora prevista</SessionFormLabel>
            <SessionInput
              type="datetime-local"
              name="estimated_timestamp"
              value={estimated_timestamp}
              onChange={(e) => {
                handleInputChange(e, formData, setFormData);
              }}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Tipo de frete</SessionFormLabel>
            <SelectComponent
              placeholder="Selecione o tipo de frota"
              name="freight_type"
              value={freight_type}
              onChange={(e) => {
                handleInputChange(e, formData, setFormData);
              }}
              options={collectionFreightTypeOptions}
            />
          </FormControl>
          {(freight_type === "Empresa" && (
            <>
              <FormControl>
                <SessionFormLabel>Motorista</SessionFormLabel>
                <SearchComponent
                  name="Motorista"
                  parameters={{ formDataName: "driver", idName: "agent_id" }}
                  selectListItem={selectListItem}
                  formData={formData}
                  setFormData={setFormData}
                  searchState={searchStates["Motorista"]}
                  handleSearchInput={handleSearchInput}
                  placeholder="Pesquise e selecione o motorista..."
                />
              </FormControl>
              <FormControl>
                <SessionFormLabel>Caminhão</SessionFormLabel>
                <SearchComponent
                  name="Caminhão"
                  parameters={{ formDataName: "truck", idName: "equip_id" }}
                  selectListItem={selectListItem}
                  formData={formData}
                  setFormData={setFormData}
                  searchState={searchStates["Caminhão"]}
                  handleSearchInput={handleSearchInput}
                  placeholder="Pesquise e selecione o caminhão..."
                />
              </FormControl>
              <FormControl>
                <SessionFormLabel>Carreta</SessionFormLabel>
              </FormControl>
              <SearchComponent
                name="Carreta"
                parameters={{ formDataName: "carriage", idName: "equip_id" }}
                selectListItem={selectListItem}
                formData={formData}
                setFormData={setFormData}
                searchState={searchStates["Carreta"]}
                handleSearchInput={handleSearchInput}
                placeholder="Pesquise e selecione o carreta..."
              />
              <FormControl>
                <SessionFormLabel>Julieta</SessionFormLabel>
                <SearchComponent
                  name="Julieta"
                  parameters={{ formDataName: "juliet", idName: "equip_id" }}
                  selectListItem={selectListItem}
                  formData={formData}
                  setFormData={setFormData}
                  searchState={searchStates["Julieta"]}
                  handleSearchInput={handleSearchInput}
                  placeholder="Pesquise e selecione a julieta..."
                />
              </FormControl>
              {collection_purpose !== "Buscar" && (
                <FormControl>
                  <SessionFormLabel>Caçambas a levar</SessionFormLabel>
                  <MultiSelectSearchComponent
                    name="Caçamba"
                    searchState={searchStates["Caçamba"]}
                    formData={formData}
                    setFormData={setFormData}
                    handleSearchInput={handleSearchInput}
                    placeholder="Pesquise e selecione as caçambas a levar..."
                  />
                </FormControl>
              )}
            </>
          )) ||
            (freight_type === "Fornecedor" && (
              <>
                <FormControl>
                  <SessionFormLabel>Transportador</SessionFormLabel>
                  <SearchComponent
                    name="Transportador"
                    parameters={{ formDataName: "carrier", idName: "agent_id" }}
                    selectListItem={selectListItem}
                    formData={formData}
                    setFormData={setFormData}
                    searchState={searchStates["Transportador"]}
                    handleSearchInput={handleSearchInput}
                    placeholder="Pesquise e selecione o transportador..."
                  />
                </FormControl>
                <FormControl>
                  <SessionFormLabel>
                    Motorista do transportador
                  </SessionFormLabel>
                  <SessionInput
                    placeholder="Digite o motorista do transportador"
                    value={driver}
                    name="driver"
                    onChange={(e) => {
                      handleInputChange(e, formData, setFormData);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <SessionFormLabel>Caminhão do transportador</SessionFormLabel>
                  <SessionInput
                    placeholder="Digite a placa do caminhão do transportador"
                    value={truck}
                    name="truck"
                    onChange={(e) => {
                      handleInputChange(e, formData, setFormData);
                    }}
                  />
                </FormControl>
                {collection_purpose !== "Buscar" && (
                  <FormControl>
                    <SessionFormLabel>Caçambas a levar</SessionFormLabel>
                    <MultiSelectSearchComponent
                      name="Caçamba"
                      searchState={searchStates["Caçamba"]}
                      formData={formData}
                      setFormData={setFormData}
                      handleSearchInput={handleSearchInput}
                      placeholder="Pesquise e selecione as caçambas a levar..."
                    />
                  </FormControl>
                )}
              </>
            ))}
          <FormControl>
            <SessionFormLabel>Observações</SessionFormLabel>
            <TextareaComponent
              placeholder="Digite aqui sua observação..."
              name="observations"
              value={observations}
              onChange={(e) => {
                handleInputChange(e, formData, setFormData);
              }}
            />
          </FormControl>
        </DrawerBody>
        <DrawerFooter>
          <SessionButton onClick={saveTransport} isLoading={buttonLoading}>
            Salvar
          </SessionButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export function CreateShipmentDrawer({
  isOpen,
  onClose,
  formData,
  setFormData,
  selectListItem,
  searchStates,
  handleSearchInput,
  handleInputChange,
  saveTransport,
  buttonLoading,
}) {
  const { estimated_timestamp, freight_type, driver, truck, observations } =
    formData;

  const shipmentFreightTypeOptions = [
    { name: "Empresa", value: "Empresa" },
    { name: "Cliente", value: "Cliente" },
  ];

  return (
    <Drawer
      size={{ sm: "sm", md: "md", lg: "lg" }}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom="0">
          <Text fontSize="3xl" fontWeight="600" color={brandColorBlue}>
            Adicionar envio
          </Text>
          <Text fontSize="sm" fontWeight="400" color="gray.700">
            Preencha as informações do novo envio.
          </Text>
        </DrawerHeader>
        <DrawerBody>
          <FormControl>
            <SessionFormLabel>Cliente</SessionFormLabel>
            <SearchComponent
              name="Cliente"
              parameters={{ formDataName: "supplier", idName: "agent_id" }}
              selectListItem={selectListItem}
              formData={formData}
              setFormData={setFormData}
              searchState={searchStates["Cliente"]}
              handleSearchInput={handleSearchInput}
              placeholder="Pesquise e selecione o cliente..."
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Data e hora prevista</SessionFormLabel>
            <SessionInput
              type="datetime-local"
              name="estimated_timestamp"
              value={estimated_timestamp}
              onChange={(e) => {
                handleInputChange(e, formData, setFormData);
              }}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Tipo de frete</SessionFormLabel>
            <SelectComponent
              placeholder="Selecione o tipo de frota"
              name="freight_type"
              value={freight_type}
              onChange={(e) => {
                handleInputChange(e, formData, setFormData);
              }}
              options={shipmentFreightTypeOptions}
            />
          </FormControl>
          {(freight_type === "Empresa" && (
            <>
              <FormControl>
                <SessionFormLabel>Motorista</SessionFormLabel>
                <SearchComponent
                  name="Motorista"
                  parameters={{ formDataName: "driver", idName: "agent_id" }}
                  selectListItem={selectListItem}
                  formData={formData}
                  setFormData={setFormData}
                  searchState={searchStates["Motorista"]}
                  handleSearchInput={handleSearchInput}
                  placeholder="Pesquise e selecione o motorista..."
                />
              </FormControl>
              <FormControl>
                <SessionFormLabel>Caminhão</SessionFormLabel>
                <SearchComponent
                  name="Caminhão"
                  parameters={{ formDataName: "truck", idName: "equip_id" }}
                  selectListItem={selectListItem}
                  formData={formData}
                  setFormData={setFormData}
                  searchState={searchStates["Caminhão"]}
                  handleSearchInput={handleSearchInput}
                  placeholder="Pesquise e selecione o caminhão..."
                />
              </FormControl>
              <FormControl>
                <SessionFormLabel>Carreta</SessionFormLabel>
                <SearchComponent
                  name="Carreta"
                  parameters={{ formDataName: "carriage", idName: "equip_id" }}
                  selectListItem={selectListItem}
                  formData={formData}
                  setFormData={setFormData}
                  searchState={searchStates["Carreta"]}
                  handleSearchInput={handleSearchInput}
                  placeholder="Pesquise e selecione o carreta..."
                />
              </FormControl>
              <FormControl>
                <SessionFormLabel>Julieta</SessionFormLabel>
                <SearchComponent
                  name="Julieta"
                  parameters={{ formDataName: "juliet", idName: "equip_id" }}
                  selectListItem={selectListItem}
                  formData={formData}
                  setFormData={setFormData}
                  searchState={searchStates["Julieta"]}
                  handleSearchInput={handleSearchInput}
                  placeholder="Pesquise e selecione a julieta..."
                />
              </FormControl>
              <FormControl>
                <SessionFormLabel>Caçambas a levar</SessionFormLabel>
                <MultiSelectSearchComponent
                  name="Caçamba"
                  searchState={searchStates["Caçamba"]}
                  formData={formData}
                  setFormData={setFormData}
                  handleSearchInput={handleSearchInput}
                  placeholder="Pesquise e selecione as caçambas a levar..."
                />
              </FormControl>
            </>
          )) ||
            (freight_type === "Fornecedor" && (
              <>
                <FormControl>
                  <SessionFormLabel>Transportador</SessionFormLabel>
                  <SearchComponent
                    name="Transportador"
                    parameters={{ formDataName: "carrier", idName: "agent_id" }}
                    selectListItem={selectListItem}
                    formData={formData}
                    setFormData={setFormData}
                    searchState={searchStates["Transportador"]}
                    handleSearchInput={handleSearchInput}
                    placeholder="Pesquise e selecione o transportador..."
                  />
                </FormControl>
                <FormControl>
                  <SessionFormLabel>
                    Motorista do transportador
                  </SessionFormLabel>
                  <SessionInput
                    placeholder="Digite o motorista do transportador"
                    value={driver}
                    name="driver"
                    onChange={(e) => {
                      handleInputChange(e, formData, setFormData);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <SessionFormLabel>Caminhão do transportador</SessionFormLabel>
                  <SessionInput
                    placeholder="Digite a placa do caminhão do transportador"
                    value={truck}
                    name="truck"
                    onChange={(e) => {
                      handleInputChange(e, formData, setFormData);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <SessionFormLabel>Caçambas a levar</SessionFormLabel>
                  <MultiSelectSearchComponent
                    name="Caçamba"
                    searchState={searchStates["Caçamba"]}
                    formData={formData}
                    setFormData={setFormData}
                    handleSearchInput={handleSearchInput}
                    placeholder="Pesquise e selecione as caçambas a levar..."
                  />
                </FormControl>
              </>
            ))}
          <FormControl>
            <SessionFormLabel>Observações</SessionFormLabel>
            <TextareaComponent
              placeholder="Digite aqui sua observação..."
              name="observations"
              value={observations}
              onChange={(e) => {
                handleInputChange(e, formData, setFormData);
              }}
            />
          </FormControl>
        </DrawerBody>
        <DrawerFooter>
          <SessionButton onClick={saveTransport} isLoading={buttonLoading}>
            Salvar
          </SessionButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export function SolidIconButton({ onClick, isDisabled }) {
  return (
    <IconButton
      size="sm"
      variant="solid"
      color="black"
      _focus={{
        outline: "3px solid",
        outlineColor: "blue.50",
      }}
      _disabled={{
        backgroundColor: "gray.400",
        color: "gray.400",
        cursor: "not-allowed",
      }}
      onClick={onClick}
      isDisabled={isDisabled}
      icon={<FiMoreVertical />}
    />
  );
}

export function FinishTransportDrawer({
  isOpen,
  onClose,
  formData,
  setFormData,
  searchStates,
  handleSearchInput,
  handleInputChange,
  handleTimeStampInputChange,
  updateTransport,
  buttonLoading,
}) {
  const { timestamps, collection_purpose, observations } = formData;

  const {
    departure_base,
    arrival_destination,
    departure_destination,
    arrival_base,
  } = timestamps;

  return (
    <Drawer
      size={{ sm: "sm", md: "md", lg: "lg" }}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom="0">
          <Text fontSize="3xl" fontWeight="600" color={brandColorBlue}>
            Finalizar coleta
          </Text>
          <Text fontSize="sm" fontWeight="400" color="gray.700">
            Revise as informações e preencha os campos obrigatórios para
            concluir a coleta.
          </Text>
        </DrawerHeader>
        <DrawerBody>
          {collection_purpose !== "Levar" && (
            <FormControl>
              <SessionFormLabel>Caçambas coletadas</SessionFormLabel>
              <MultiSelectSearchComponent
                name="Caçamba"
                searchState={searchStates["Caçamba"]}
                formData={formData}
                setFormData={setFormData}
                handleSearchInput={handleSearchInput}
                placeholder="Pesquise e selecione as caçambas coletadas..."
              />
            </FormControl>
          )}
          <FormControl>
            <SessionFormLabel>Saída da base</SessionFormLabel>
            <SessionInput
              type="datetime-local"
              name="departure_base"
              value={departure_base}
              onChange={(e) => {
                handleTimeStampInputChange(e, formData, setFormData);
              }}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Chegada ao destino</SessionFormLabel>
            <SessionInput
              type="datetime-local"
              name="arrival_destination"
              value={arrival_destination}
              onChange={(e) => {
                handleTimeStampInputChange(e, formData, setFormData);
              }}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Saída do destino</SessionFormLabel>
            <SessionInput
              type="datetime-local"
              name="departure_destination"
              value={departure_destination}
              onChange={(e) => {
                handleTimeStampInputChange(e, formData, setFormData);
              }}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Retorno à base</SessionFormLabel>
            <SessionInput
              type="datetime-local"
              name="arrival_base"
              value={arrival_base}
              onChange={(e) => {
                handleTimeStampInputChange(e, formData, setFormData);
              }}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Observações do motorista</SessionFormLabel>
            <TextareaComponent
              placeholder="Digite aqui sua observação..."
              name="observations"
              value={observations}
              onChange={handleInputChange}
            />
          </FormControl>
        </DrawerBody>
        <DrawerFooter>
          <SessionButton onClick={updateTransport} isLoading={buttonLoading}>
            Finalizar transporte
          </SessionButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export function AddMaterialDrawer({
  isOpen,
  onClose,
  formData,
  setFormData,
  selectListItem,
  searchStates,
  handleSearchInput,
  handleInputChange,
  sendMaterialAddition,
  buttonLoading,
}) {
  const { quantity, timestamp, observations } = formData;

  return (
    <Drawer
      size={{ sm: "sm", md: "md", lg: "lg" }}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom="0">
          <Text fontSize="3xl" fontWeight="600" color={brandColorBlue}>
            Adição manual de estoque
          </Text>
          <Text fontSize="sm" fontWeight="400" color="gray.700">
            Preencha as informações para fazer a adição manual de estoque.
          </Text>
        </DrawerHeader>
        <DrawerBody>
          <FormControl>
            <SessionFormLabel>Material a adicionar</SessionFormLabel>
            <SearchComponent
              name="Material"
              parameters={{
                formDataName: "material_id",
                idName: "material_id",
              }}
              selectListItem={selectListItem}
              formData={formData}
              setFormData={setFormData}
              searchState={searchStates["Material"]}
              handleSearchInput={handleSearchInput}
              placeholder="Pesquise e selecione o material..."
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Quantidade</SessionFormLabel>
            <NumberInputComponent
              name="quantity"
              value={quantity}
              onChange={(e) => {
                const temp = {
                  target: {
                    name: "quantity",
                    value: Number(e),
                  },
                };
                handleInputChange(temp, formData, setFormData);
              }}
              placeholder="Insira quantidade"
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Data e hora</SessionFormLabel>
            <SessionInput
              type="datetime-local"
              name="timestamp"
              value={timestamp}
              onChange={(e) => {
                handleInputChange(e, formData, setFormData);
              }}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Observações</SessionFormLabel>
            <TextareaComponent
              placeholder="Digite aqui sua observação..."
              name="observations"
              value={observations}
              onChange={(e) => {
                handleInputChange(e, formData, setFormData);
              }}
            />
          </FormControl>
        </DrawerBody>
        <DrawerFooter>
          <SessionButton
            onClick={sendMaterialAddition}
            isLoading={buttonLoading}
          >
            Salvar
          </SessionButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export function DeleteMaterialDrawer({
  isOpen,
  onClose,
  formData,
  setFormData,
  selectListItem,
  searchStates,
  handleSearchInput,
  handleInputChange,
  sendMaterialDeletion,
  buttonLoading,
}) {
  const { quantity, timestamp, observations } = formData;

  return (
    <Drawer
      size={{ sm: "sm", md: "md", lg: "lg" }}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom="0">
          <Text fontSize="3xl" fontWeight="600" color={brandColorBlue}>
            Deleção manual de estoque
          </Text>
          <Text fontSize="sm" fontWeight="400" color="gray.700">
            Preencha as informações para fazer a deleção manual de estoque.
          </Text>
        </DrawerHeader>
        <DrawerBody>
          <FormControl>
            <SessionFormLabel>Material a deletar</SessionFormLabel>
            <SearchComponent
              name="Material"
              parameters={{
                formDataName: "material_id",
                idName: "material_id",
              }}
              selectListItem={selectListItem}
              formData={formData}
              setFormData={setFormData}
              searchState={searchStates["Material"]}
              handleSearchInput={handleSearchInput}
              placeholder="Pesquise e selecione o material..."
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Quantidade</SessionFormLabel>
            <NumberInputComponent
              name="quantity"
              value={quantity}
              onChange={(e) => {
                const temp = {
                  target: {
                    name: "quantity",
                    value: Number(e),
                  },
                };
                handleInputChange(temp, formData, setFormData);
              }}
              placeholder="Insira quantidade"
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Data e hora</SessionFormLabel>
            <SessionInput
              type="datetime-local"
              name="timestamp"
              value={timestamp}
              onChange={(e) => {
                handleInputChange(e, formData, setFormData);
              }}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Observações</SessionFormLabel>
            <TextareaComponent
              placeholder="Digite aqui sua observação..."
              name="observations"
              value={observations}
              onChange={(e) => {
                handleInputChange(e, formData, setFormData);
              }}
            />
          </FormControl>
        </DrawerBody>
        <DrawerFooter>
          <SessionButton
            onClick={sendMaterialDeletion}
            isLoading={buttonLoading}
          >
            Salvar
          </SessionButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export function ConvertMaterialDrawer({
  isOpen,
  onClose,
  formData,
  setFormData,
  net_quantity,
  selectListItem,
  searchStates,
  handleSearchInput,
  handleInputChange,
  sendMaterialConversion,
  buttonLoading,
}) {
  const { gross_quantity, discounted_quantity, timestamp, observations } =
    formData;

  return (
    <Drawer
      size={{ sm: "sm", md: "md", lg: "lg" }}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom="0">
          <Text fontSize="3xl" fontWeight="600" color={brandColorBlue}>
            Conversão de estoque
          </Text>
          <Text fontSize="sm" fontWeight="400" color="gray.700">
            Preencha as informações para fazer a conversão de estoque.
          </Text>
        </DrawerHeader>
        <DrawerBody>
          <FormControl>
            <SessionFormLabel>Material inicial</SessionFormLabel>
            <SearchComponent
              name="MaterialInicial"
              parameters={{
                formDataName: "initial_material_id",
                idName: "material_id",
              }}
              selectListItem={selectListItem}
              formData={formData}
              setFormData={setFormData}
              searchState={searchStates["MaterialInicial"]}
              handleSearchInput={handleSearchInput}
              placeholder="Pesquise e selecione o material..."
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Material final</SessionFormLabel>
            <SearchComponent
              name="MaterialFinal"
              parameters={{
                formDataName: "final_material_id",
                idName: "material_id",
              }}
              selectListItem={selectListItem}
              formData={formData}
              setFormData={setFormData}
              searchState={searchStates["MaterialFinal"]}
              handleSearchInput={handleSearchInput}
              placeholder="Pesquise e selecione o material..."
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Quantidade bruta</SessionFormLabel>
            <NumberInputComponent
              name="gross_quantity"
              value={gross_quantity}
              onChange={(e) => {
                const temp = {
                  target: {
                    name: "gross_quantity",
                    value: Number(e),
                  },
                };
                handleInputChange(temp, formData, setFormData);
              }}
              placeholder="Insira quantidade"
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Quantidade descontada</SessionFormLabel>
            <NumberInputComponent
              name="discounted_quantity"
              value={discounted_quantity}
              onChange={(e) => {
                const temp = {
                  target: {
                    name: "discounted_quantity",
                    value: Number(e),
                  },
                };
                handleInputChange(temp, formData, setFormData);
              }}
              placeholder="Insira quantidade"
            />
          </FormControl>
          <SessionFormLabel>Quantidade líquida</SessionFormLabel>
          <Text>{net_quantity}kg</Text>
          <FormControl>
            <SessionFormLabel>Data e hora</SessionFormLabel>
            <SessionInput
              type="datetime-local"
              name="timestamp"
              value={timestamp}
              onChange={(e) => {
                handleInputChange(e, formData, setFormData);
              }}
            />
          </FormControl>
          <FormControl>
            <SessionFormLabel>Observações</SessionFormLabel>
            <TextareaComponent
              placeholder="Digite aqui sua observação..."
              name="observations"
              value={observations}
              onChange={(e) => {
                handleInputChange(e, formData, setFormData);
              }}
            />
          </FormControl>
        </DrawerBody>
        <DrawerFooter>
          <SessionButton
            onClick={sendMaterialConversion}
            isLoading={buttonLoading}
          >
            Salvar
          </SessionButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
