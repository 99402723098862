import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { SessionButton } from "../../../components/SessionComponents";
import { CognitoSessionContext } from "../../../contexts/CognitoSession";
import {
  CreateShipmentDrawer,
  FinishTransportDrawer,
  SolidIconButton,
} from "../../../components/AdminComponents";
import {
  finishTransport,
  getTransport,
  postTransport,
} from "../../../services/Transports";
import { getAgentByType } from "../../../services/Agents";
import { getEquipmentsByType } from "../../../services/Equipments";
import dayjs from "dayjs";

export default function ShipmentsTab({ activeTab }) {
  const [userTransports, setUserTransports] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [searchStates, setSearchStates] = useState({});
  const [createShipmentFormData, setCreateShipmentFormData] = useState({
    transport_type: "Envio",
    client: "",
    estimated_timestamp: "",
    freight_type: "",
    carrier: "",
    driver: "",
    truck: "",
    carriage: "",
    juliet: "",
    origin_dumpsters: [],
    observations: "",
    transport_status: "Pendente",
  });
  const [finishShipmentFormData, setFinishShipmentFormData] = useState({
    transport_id: "",
    destination_dumpsters: [],
    timestamps: {
      departure_base: "",
      arrival_destination: "",
      departure_destination: "",
      arrival_base: "",
    },
    observations: "",
  });
  const {
    isOpen: drawerCreateTransportIsOpen,
    onOpen: drawerCreateTransportOnOpen,
    onClose: drawerCreateCollectionOnClose,
  } = useDisclosure();
  const {
    isOpen: drawerFinishTransportsOpen,
    onOpen: drawerFinishTransportOnOpen,
    onClose: drawerFinishTransportOnClose,
  } = useDisclosure();

  const cognitoUser = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  const toast = useToast();

  async function fetchShipments() {
    const response = await getTransport(
      cognitoUser.idToken.jwtToken,
      meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
      "Envio",
      logOut
    );
    console.log(response);

    setUserTransports(response);
  }

  useEffect(() => {
    if (activeTab === "Shipments") {
      fetchShipments();
    }
  }, [activeTab]);

  const { logOut } = useContext(CognitoSessionContext);

  function handleInputChange(e, formData, setFormData) {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  function handleTimeStampInputChange(e, formData, setFormData) {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      timestamps: {
        ...formData["timestamps"],
        [name]: value,
      },
    });
  }

  const debounceTimeoutRef = useRef(null);

  function handleSearchInput(e, name) {
    const value = e.target.value;
    let newSearchState = {
      ...searchStates,
      [name]: {
        ...searchStates[name],
        query: value,
        loading: true,
        results: [],
      },
    };
    setSearchStates(newSearchState);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(async () => {
      if (value.length > 1) {
        if (
          name === "Fornecedor" ||
          name === "Motorista" ||
          name === "Transportador"
        ) {
          const response = await getAgentByType(
            cognitoUser.idToken.jwtToken,
            meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
            name,
            value,
            logOut
          );
          newSearchState = {
            ...newSearchState,
            [name]: {
              ...newSearchState[name],
              loading: false,
              results: response,
            },
          };
          setSearchStates(newSearchState);
        } else if (
          name === "Caminhão" ||
          name === "Carreta" ||
          name === "Julieta"
        ) {
          const response = await getEquipmentsByType(
            cognitoUser.idToken.jwtToken,
            meetalUser.org_id,
            "Veículo",
            name,
            value,
            logOut
          );
          newSearchState = {
            ...newSearchState,
            [name]: {
              ...newSearchState[name],
              loading: false,
              results: response,
            },
          };
          setSearchStates(newSearchState);
        } else if (name === "Caçamba") {
          const response = await getEquipmentsByType(
            cognitoUser.idToken.jwtToken,
            meetalUser.org_id,
            name,
            "",
            value,
            logOut
          );

          newSearchState = {
            ...newSearchState,
            [name]: {
              ...newSearchState[name],
              loading: false,
              results: response,
            },
          };
          setSearchStates(newSearchState);
        }
      } else {
        newSearchState = {
          ...newSearchState,
          [name]: {
            ...newSearchState[name],
            loading: false,
            results: [],
          },
        };
        setSearchStates(newSearchState);
      }
    }, 1000);
  }

  function selectListItem(
    formData,
    setFormData,
    { formDataName, id, propName, queryDisplay }
  ) {
    const newFormData = { ...formData, [formDataName]: id };
    setFormData(newFormData);
    let newSearchState = {
      ...searchStates,
      [propName]: {
        ...searchStates[propName],
        query: queryDisplay,
        results: [],
      },
    };
    setSearchStates(newSearchState);
  }

  async function sendCreateShipmentForm() {
    const body = {
      ...createShipmentFormData,
      estimated_timestamp: dayjs(
        createShipmentFormData.estimated_timestamp
      ).valueOf(),
      agent_id: createShipmentFormData.supplier,
      supplier: "",
      org_id: meetalUser.org_id,
      admin_id: meetalUser.meetal_user_id,
      branch_id: meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
    };

    for (let key in body) {
      if (!body[key]) {
        delete body[key];
      }
    }
    console.log(body);

    const response = await postTransport(
      cognitoUser.idToken.jwtToken,
      body,
      logOut
    );
    console.log(response);
    if (response.data.statusCode !== 200) {
      toast({
        title: "Erro ao salvar",
        description: "Tente novamente",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
    } else {
      toast({
        title: "Transporte criado com sucesso",
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
    }
    setButtonLoading(false);
  }

  async function sendFinishShipmentForm() {
    const timestamps = finishShipmentFormData["timestamps"];
    for (let key in timestamps) {
      timestamps[key] = dayjs(timestamps[key]).valueOf();
    }

    const transport = userTransports.filter((t) => {
      if (t.transport_id === finishShipmentFormData.transport_id) {
        return t.agent_id;
      }
    });
    console.log(transport);

    const body = {
      ...finishShipmentFormData,
      timestamps,
      agent_id: transport[0].agent_id,
      org_id: meetalUser.org_id,
      admin_id: meetalUser.meetal_user_id,
      branch_id: meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
    };

    for (let key in body) {
      if (!body[key]) {
        delete body[key];
      }
    }

    console.log(body);

    const response = await finishTransport(
      cognitoUser.idToken.jwtToken,
      body,
      logOut
    );
    console.log(response);
    if (response.data.statusCode !== 200) {
      toast({
        title: "Erro ao finalizar coleta",
        description: "Tente novamente",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
    } else {
      toast({
        title: "Transporte finalizado com sucesso",
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
    }
    setButtonLoading(false);
  }

  return (
    <>
      <SessionButton width="200px" onClick={drawerCreateTransportOnOpen}>
        Adicionar envio
      </SessionButton>
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        borderRadius="8px"
        padding="15px"
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Status</Th>
              <Th>Apelido do agente</Th>
              <Th>Motorista</Th>
              <Th>Veículo</Th>
              <Th>Objetivo</Th>
              <Th>Data e hora prevista</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userTransports.map((t) => (
              <Tr key={t.transport_id}>
                <Td>{t.transport_id.split("-")[0]}</Td>
                <Td>{t.transport_status}</Td>
                <Td>{t.agent_nickname || "---"}</Td>
                <Td>{t.driver_name || "---"}</Td>
                <Td>{t.equipment_code || "---"}</Td>
                <Td>{t.collection_purpose}</Td>
                <Td>
                  {dayjs
                    .unix(t.estimated_timestamp / 1000)
                    .format("DD/MM/YYYY - HH:mm")}
                </Td>
                <Td>
                  <SolidIconButton
                    onClick={() => {
                      const updatedFormData = {
                        ...finishShipmentFormData,
                        transport_id: t.transport_id,
                        collection_purpose: t.collection_purpose,
                      };
                      setFinishShipmentFormData(updatedFormData);
                      drawerFinishTransportOnOpen();
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <CreateShipmentDrawer
        isOpen={drawerCreateTransportIsOpen}
        onClose={drawerCreateCollectionOnClose}
        formData={createShipmentFormData}
        setFormData={setCreateShipmentFormData}
        selectListItem={selectListItem}
        searchStates={searchStates}
        handleSearchInput={handleSearchInput}
        handleInputChange={handleInputChange}
        saveTransport={sendCreateShipmentForm}
        buttonLoading={buttonLoading}
      />
      <FinishTransportDrawer
        isOpen={drawerFinishTransportsOpen}
        onClose={drawerFinishTransportOnClose}
        formData={finishShipmentFormData}
        setFormData={setFinishShipmentFormData}
        selectListItem={selectListItem}
        searchStates={searchStates}
        handleSearchInput={handleSearchInput}
        handleTimeStampInputChange={handleTimeStampInputChange}
        handleInputChange={handleInputChange}
        updateTransport={sendFinishShipmentForm}
        buttonLoading={buttonLoading}
      />
    </>
  );
}
