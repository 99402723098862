import {
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import Header from "../../components/Global/Header";
import Sidebar from "../../components/Global/Sidebar";
import AdminPageContainer from "../../components/Global/AdminPageContainer";
import AdminPageContent from "../../components/Global/AdminPageContent";
import SubHeader from "../../components/Global/SubHeader";
import Footer from "../../components/Global/Footer";
import { useState } from "react";
import CollectionsTab from "./TransportsTabs/CollectionsTab";
import ShipmentsTab from "./TransportsTabs/ShipmentsTab";

export default function TransportsPage() {
  const [activeTab, setActiveTab] = useState("Collections");

  return (
    <>
      <Header />
      <HStack>
        <Sidebar />
        <AdminPageContainer>
          <SubHeader>Transportes</SubHeader>
          <AdminPageContent>
            <Tabs margin="35px 0 0 25px">
              <TabList>
                <Tab onClick={() => setActiveTab("Collections")}>Coletas</Tab>
                <Tab onClick={() => setActiveTab("Shipments")}>Envio</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <CollectionsTab activeTab={activeTab} />
                </TabPanel>
                <TabPanel>
                  <ShipmentsTab activeTab={activeTab} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </AdminPageContent>
          <Footer />
        </AdminPageContainer>
      </HStack>
    </>
  );
}
