import axios from "axios";
import checkIfSessionIsValid from "../utils/checkIfSessionIsValid";

export async function postEquipments(idToken, body, logOut) {
  const url =
    "https://bhpfeamtx1.execute-api.sa-east-1.amazonaws.com/dev/equipment";
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const JsonBody = JSON.stringify(body);

  const promise = axios.post(url, JsonBody, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}

export async function getEquipments(idToken, org_id, logOut) {
  const url = `https://bhpfeamtx1.execute-api.sa-east-1.amazonaws.com/dev/equipment?org_id=${org_id}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const promise = axios.get(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return JSON.parse(response.data.body);
}
export async function getEquipmentsByType(
  idToken,
  org_id,
  equipment_type,
  vehicle_type,
  query,
  logOut
) {
  const url = `https://bhpfeamtx1.execute-api.sa-east-1.amazonaws.com/dev/equipment?org_id=${org_id}&equipment_type=${equipment_type}&vehicle_type=${vehicle_type}&q=${query}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const promise = axios.get(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return JSON.parse(response.data.body);
}
