import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Switch,
  Tag,
  Text,
  Textarea,
  VStack,
  Image,
  Box,
  InputRightElement,
  Spinner,
  List,
  ListItem,
} from "@chakra-ui/react";
import { brandColorBlue } from "../GlobalStyles";
import { FiUpload, FiFile, FiTrash2 } from "react-icons/fi";
import { HiCheckCircle } from "react-icons/hi";
import ReactSelect from "react-select";

export function NumberInputComponent({
  name,
  value,
  onChange,
  precision,
  isDisabled,
  placeholder,
  width,
}) {
  return (
    <NumberInput
      size="lg"
      width={width}
      borderColor="gray.200"
      color={brandColorBlue}
      backgroundColor="white"
      name={name}
      value={value}
      onChange={onChange}
      precision={precision}
      min={0}
      isDisabled={isDisabled}
    >
      <NumberInputField
        _placeholder={{
          color: "gray.400",
        }}
        _hover={{
          borderColor: "gray.900",
        }}
        _focus={{
          borderColor: "gray.900",
          outline: "3px solid",
          outlineColor: "blue.50",
        }}
        _disabled={{
          color: "gray.400",
          backgroundColor: "gray.100",
          borderColor: "gray.300",
          cursor: "not-allowed",
        }}
        _invalid={{
          border: "2px",
          borderColor: "red.500",
        }}
        placeholder={placeholder}
      />
    </NumberInput>
  );
}

export function NumberInputWithStepperComponent({
  name,
  value,
  onChange,
  placeholder,
}) {
  return (
    <NumberInput
      size="lg"
      borderColor="gray.200"
      color={brandColorBlue}
      backgroundColor="white"
      name={name}
      value={value}
      onChange={onChange}
      min={0}
      clampValueOnBlur={true}
    >
      <NumberInputField
        _placeholder={{
          color: "gray.400",
        }}
        _hover={{
          borderColor: "gray.900",
        }}
        _focus={{
          borderColor: "gray.900",
          outline: "3px solid",
          outlineColor: "blue.50",
        }}
        _disabled={{
          color: "gray.400",
          backgroundColor: "gray.100",
          borderColor: "gray.300",
          cursor: "not-allowed",
        }}
        _invalid={{
          border: "2px",
          borderColor: "red.500",
        }}
        placeholder={placeholder}
      />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
}

export function TextareaComponent({ placeholder, name, value, onChange }) {
  return (
    <Textarea
      size="lg"
      borderColor="gray.200"
      color={brandColorBlue}
      backgroundColor="white"
      _placeholder={{
        color: "gray.400",
      }}
      _hover={{
        borderColor: "gray.900",
      }}
      _focus={{
        borderColor: "gray.900",
        outline: "3px solid",
        outlineColor: "blue.50",
      }}
      _disabled={{
        color: "gray.400",
        backgroundColor: "gray.100",
        borderColor: "gray.300",
        cursor: "not-allowed",
      }}
      _invalid={{
        border: "2px",
        borderColor: "red.500",
      }}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
}

export function SelectComponent({
  placeholder,
  name,
  onChange,
  options,
  value,
}) {
  return (
    <Select
      size="lg"
      borderColor="gray.200"
      color={brandColorBlue}
      backgroundColor="white"
      _placeholder={{
        color: "gray.400",
      }}
      _hover={{
        borderColor: "gray.900",
      }}
      _focus={{
        backgroundColor: "white",
        borderColor: "gray.900",
        outline: "3px solid",
        outlineColor: "blue.50",
      }}
      _disabled={{
        color: "gray.400",
        backgroundColor: "gray.100",
        borderColor: "gray.300",
        cursor: "not-allowed",
      }}
      _invalid={{
        border: "2px",
        borderColor: "red.500",
      }}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    >
      {options.map((o) => (
        <option key={o.value} value={o.value}>
          {o.name}
        </option>
      ))}
    </Select>
  );
}

export function AlertComponent({ title, description, status }) {
  return (
    <Alert
      size="lg"
      borderRadius="8px"
      marginTop="15px"
      display="flex"
      alignItems="start"
      status={status}
    >
      {(status === "warning" && <AlertIcon color="orange.500" />) ||
        (status === "info" && <AlertIcon color="blue.500" />)}
      <VStack alignItems="start">
        <AlertTitle fontSize="md" fontWeight="bold" color="gray.700">
          {title}
        </AlertTitle>
        <AlertDescription fontSize="md" color="gray.700">
          {description}
        </AlertDescription>
      </VStack>
    </Alert>
  );
}

export function OutlineButtonComponent({
  children,
  size,
  onClick,
  isDisabled,
  icon,
  marginTop,
}) {
  return (
    <Button
      size={size}
      variant="outline"
      marginTop={marginTop}
      color={brandColorBlue}
      border="1px solid"
      borderColor={brandColorBlue}
      _focus={{
        outline: "3px solid",
        outlineColor: "blue.50",
      }}
      _disabled={{
        backgroundColor: "gray.400",
        color: "gray.400",
        cursor: "not-allowed",
      }}
      onClick={onClick}
      isDisabled={isDisabled}
      leftIcon={icon}
    >
      {children}
    </Button>
  );
}

export function InputWithAddonComponent({
  size,
  leftAddon,
  width,
  placeholder,
  defaultValue,
  type,
  value,
  name,
  onChange,
  isDisabled,
  isInvalid,
  rightAddon,
}) {
  return (
    <InputGroup size={size}>
      {leftAddon ? (
        <InputLeftAddon width="50px" color="gray.500">
          {leftAddon}
        </InputLeftAddon>
      ) : (
        <></>
      )}
      <Input
        width={width}
        borderColor="gray.200"
        color="gray.700"
        backgroundColor="white"
        _placeholder={{
          color: "gray.400",
        }}
        _hover={{
          borderColor: "gray.900",
        }}
        _focus={{
          borderColor: "gray.900",
          outline: "3px solid",
          outlineColor: "blue.50",
        }}
        _disabled={{
          color: "gray.400",
          backgroundColor: "gray.100",
          borderColor: "gray.300",
          cursor: "not-allowed",
        }}
        _invalid={{
          border: "2px",
          borderColor: "red.500",
        }}
        placeholder={placeholder}
        defaultValue={defaultValue}
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
      />
      {rightAddon ? (
        <InputRightAddon width="50px" color="gray.500">
          {rightAddon}
        </InputRightAddon>
      ) : (
        <></>
      )}
    </InputGroup>
  );
}

export function InputFileComponent({
  width,
  multiple,
  onChange,
  image,
  setImage,
}) {
  return (
    <>
      {!image ? (
        <FormControl as="form">
          <FormControl>
            <FormLabel
              width={width}
              height="190px"
              border="1px dashed"
              borderColor="gray.400"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap="10px"
              cursor="pointer"
            >
              <Icon boxSize={6} as={FiUpload} color="black" />
              <Text fontSize="lg" fontWeight="600" color="gray.900">
                Para enviar aqruivos,{" "}
                <Text
                  as="b"
                  fontWeight="600"
                  color="gray.900"
                  textDecoration="underline"
                >
                  selecione os arquivos
                </Text>{" "}
                do seu computador
              </Text>
            </FormLabel>
            <InputGroup position="relative">
              <Input
                display="none"
                type="file"
                multiple={multiple}
                onChange={onChange}
              />
            </InputGroup>
          </FormControl>
        </FormControl>
      ) : (
        <Flex
          width="100%"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="8px"
          padding="25px 15px"
          flexDirection="column"
          gap="20px"
        >
          <HStack justifyContent="space-between">
            <HStack alignItems="center">
              <Icon boxSize={5} as={FiFile} color="gray.900" />
              <Text fontSize="lg" fontWeight="600" color="gray.900">
                {image.name}
              </Text>
            </HStack>
            <Text fontSize="md" color="gray.500">
              {(image.size / 1000).toFixed(2)} KB
            </Text>
          </HStack>
          <HStack justifyContent="space-between">
            <HStack alignItems="center">
              <Icon boxSize={5} as={HiCheckCircle} color="green.400" />
              <Text fontSize="md" fontWeight="500" color="gray.900">
                Concluído
              </Text>
            </HStack>
            <HStack
              alignItems="center"
              cursor="pointer"
              _hover={{
                textDecoration: "underline",
              }}
              onClick={() => setImage("")}
            >
              <Icon boxSize={5} as={FiTrash2} color="black" />
              <Text fontSize="md" fontWeight="500" color="gray.900">
                Excluir arquivo
              </Text>
            </HStack>
          </HStack>
        </Flex>
      )}
    </>
  );
}

export function SwitchLabel({ children }) {
  return (
    <FormLabel fontSize="md" color="black" margin="0">
      {children}
    </FormLabel>
  );
}

export function SwitchComponent({}) {
  return <Switch />;
}

export function QrCodeModal({
  isOpen,
  onClose,
  headerText,
  qrCode,
  bodyText,
  refreshButtonOnClick,
  refreshButtonText,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent alignItems="center">
        <ModalHeader fontSize="lg" fontWeight="bold" color="gray.700">
          {headerText}
        </ModalHeader>
        <Image width="250px" height="250px" src={qrCode} />
        <ModalBody fontSize="md" color="gray.700">
          {bodyText}
        </ModalBody>
        <ModalFooter>
          <Button
            width="100%"
            backgroundColor={brandColorBlue}
            color="white"
            _hover={{
              backgroundColor: "gray.900",
            }}
            onClick={refreshButtonOnClick}
          >
            {refreshButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function TagComponent({
  children,
  size,
  fontSize,
  color,
  backgroundColor,
  borderRadius,
  position,
  top,
  left,
  zIndex,
}) {
  return (
    <Tag
      size={size}
      fontSize={fontSize}
      fontWeight="600"
      variant="solid"
      color={color}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      paddingInline="5px"
      position={position ? position : "static"}
      top={top}
      left={left}
      zIndex={zIndex ? zIndex : "0"}
    >
      {children}
    </Tag>
  );
}

export function SearchComponent({
  name,
  parameters,
  selectListItem,
  formData,
  setFormData,
  searchState,
  handleSearchInput,
  placeholder,
}) {
  const { query, loading, results } = searchState || {
    query: "",
    loading: false,
    results: [],
  };
  return (
    <Box width="100%">
      <InputGroup>
        <Input
          backgroundColor="white"
          value={query}
          onChange={(e) => {
            handleSearchInput(e, name);
          }}
          placeholder={placeholder}
        />
        {loading && (
          <InputRightElement>
            <Spinner size="sm" />
          </InputRightElement>
        )}
      </InputGroup>
      {results.length > 0 && (
        <List
          border="1px solid #ccc"
          borderRadius="md"
          mt="2"
          maxH="200px"
          overflowY="auto"
        >
          {results.map((r) => (
            <ListItem
              key={r}
              p="2"
              _hover={{ bg: "gray.100", cursor: "pointer" }}
              onClick={() => {
                const temp = {
                  ...parameters,
                  id: r[parameters.idName],
                  propName: name,
                  queryDisplay: r.result_display,
                };
                selectListItem(formData, setFormData, temp);
              }}
            >
              <Text>{r.result_display}</Text>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}

export function MultiSelectSearchComponent({
  name,
  searchState,
  formData,
  setFormData,
  handleSearchInput,
  placeholder,
}) {
  const { query, loading, results } = searchState || {
    query: "",
    loading: false,
    results: [],
  };

  const options = results.map((r) => {
    return { label: r.result_display, value: r.equip_id };
  });

  return (
    <ReactSelect
      options={options}
      isMulti
      placeholder={placeholder}
      onChange={(e) => {
        const dumpsters_id = e.map((d) => {
          return d.value;
        });
        setFormData({ ...formData, destination_dumpsters: dumpsters_id });
      }}
      onInputChange={(e) => {
        const temp = {
          target: {
            value: e,
          },
        };

        handleSearchInput(temp, name);
      }}
      styles={{
        control: (styles) => ({
          ...styles,
          backgroundColor: "white",
          width: "100%",
        }),
      }}
    />
  );
}
