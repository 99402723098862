import {
  Avatar,
  AvatarBadge,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { brandColorBlue } from "../../GlobalStyles";
import LogoWithWhiteName from "./LogoWithWhiteName";
import { FiChevronDown, FiLogOut } from "react-icons/fi";
import { useContext, useEffect, useState } from "react";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import { getUser } from "../../services/User";
import { QrCodeModal, SelectComponent } from "../GlobalComponents";

export default function Header() {
  const [qrCode, setQrCode] = useState("");
  const [branchOptions, setBranchOptions] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { logOut } = useContext(CognitoSessionContext);

  const data = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const { name, surname, onboarding_finished, user_email, branches } =
    JSON.parse(window.localStorage.getItem("MeetalUser"));

  useEffect(() => {
    const tempArray = branches.map((b) => {
      return { name: b.branch_name, value: b.branch_id };
    });
    setBranchOptions(tempArray);
  }, []);

  return (
    <>
      <Flex
        width="100%"
        height="80px"
        backgroundColor={brandColorBlue}
        alignItems="center"
        padding="0 1.5vw"
        position="fixed"
        top="0"
        right="0"
        zIndex="2"
      >
        <LogoWithWhiteName />
        <Spacer />
        <HStack gap="1.5vw">
          <SelectComponent
            name="branch"
            // value={m.name_and_type}
            // onChange={(e) => {
            //   const target = {
            //     name: "material",
            //     value: e.target.value,
            //   };
            //   handleChange(index, target);
            // }}
            options={branchOptions}
          />
          <HStack>
            <Avatar
              size="md"
              name={name + " " + surname}
              backgroundColor="gray.600"
              color="white"
            >
              <AvatarBadge
                boxSize="20px"
                bg="green.300"
                border="3px solid"
                borderColor={brandColorBlue}
              />
            </Avatar>
            <Menu>
              <MenuButton
                as={Button}
                size="lg"
                fontWeight="400"
                variant="ghost"
                color="white"
                padding="0"
                rightIcon={<FiChevronDown />}
                _hover={{
                  backgroundColor: "none",
                }}
                _active={{
                  backgroundColor: "none",
                }}
              >
                {name + " " + surname}
              </MenuButton>
              <MenuList>
                <MenuItem
                  icon={<FiLogOut />}
                  color="gray.800"
                  _hover={{
                    backgroundColor: "gray.200",
                  }}
                  onClick={logOut}
                >
                  Sair
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </HStack>
      </Flex>
      <QrCodeModal
        isOpen={isOpen}
        onClose={onClose}
        headerText="Configuração do pagamento"
        qrCode={qrCode}
        bodyText="Aponte a câmera do seu celular e siga as instruções"
        refreshButtonOnClick={async () => {
          const response = await getUser(
            "/user_metadata",
            data.idToken.jwtToken,
            user_email
          );
          window.localStorage.setItem(
            "MeetalUser",
            JSON.stringify(response.data["body-json"].user_metadata)
          );
          onClose();
        }}
        refreshButtonText="Fechar"
      />
    </>
  );
}
