import axios from "axios";
import checkIfSessionIsValid from "../utils/checkIfSessionIsValid";

export async function postMaterialLabels(idToken, body, logOut) {
  const url = `https://ecb9gwu854.execute-api.sa-east-1.amazonaws.com/prod/label`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const JsonBody = JSON.stringify(body);

  const promise = axios.post(url, JsonBody, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}

export async function getMaterialLabels(idToken, branch_id, logOut) {
  const url = `https://ecb9gwu854.execute-api.sa-east-1.amazonaws.com/prod/label?branch_id=${branch_id}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const promise = axios.get(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}

export async function putMaterialLabels(idToken, body, logOut) {
  const url = `https://ecb9gwu854.execute-api.sa-east-1.amazonaws.com/prod/label`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const JsonBody = JSON.stringify(body);

  const promise = axios.put(url, JsonBody, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}

export async function postMaterial(idToken, body, logOut) {
  const url = `https://ecb9gwu854.execute-api.sa-east-1.amazonaws.com/prod/materials`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const JsonBody = JSON.stringify(body);

  const promise = axios.post(url, JsonBody, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}

export async function getMaterials(idToken, branch_id, logOut) {
  const url = `https://ecb9gwu854.execute-api.sa-east-1.amazonaws.com/prod/materials?branch_id=${branch_id}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const promise = axios.get(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}

export async function getMaterialsByType(idToken, branch_id, query, logOut) {
  const url = `https://ecb9gwu854.execute-api.sa-east-1.amazonaws.com/prod/materials?branch_id=${branch_id}&q=${query}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const promise = axios.get(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return JSON.parse(response.data.body);
}

export async function getStockMaterials(idToken, branch_id, logOut) {
  const url = `https://ecb9gwu854.execute-api.sa-east-1.amazonaws.com/prod/materials?branch_id=${branch_id}&is_stock=${true}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const promise = axios.get(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return JSON.parse(response.data.body);
}

export async function putMaterialQuantity(idToken, body, logOut) {
  const url = `https://ecb9gwu854.execute-api.sa-east-1.amazonaws.com/prod/materials`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const JsonBody = JSON.stringify(body);

  const promise = axios.put(url, JsonBody, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}
