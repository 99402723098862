import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { SessionButton } from "../../../components/SessionComponents";
import { CognitoSessionContext } from "../../../contexts/CognitoSession";
import { getAgents, postAgent } from "../../../services/Agents";
import { CreateAgentDrawer } from "../../../components/AdminComponents";

export default function AgentsTab({ activeTab }) {
  const [userAgents, setUserAgents] = useState([]);
  const [agentTypes, setAgentTypes] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formData, setFormData] = useState({
    nickname: "",
    agent_types: [],
    entity_type: "PJ",
    name: "",
    brand_name: "",
    cpf: "",
    cnpj: "",
    rg: "",
    state_registration: "",
    email: "",
    receipt_email: "",
    whatsapp: "",
    phone: "",
    website: "",
    observations: "",
    status: "Ativo",
    location: {
      cep: "",
      state: "",
      city: "",
      neighborhood: "",
      street: "",
      number: "",
      complement: "",
    },
    bank_account: {
      payment_type: "",
      brand_name_or_name: "",
      cnpj_or_cpf: "",
      pix_key: "",
      bank: "",
      bank_branch: "",
      account: "",
    },
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const cognitoUser = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  const toast = useToast();

  async function fetchAgents() {
    const response = await getAgents(
      cognitoUser.idToken.jwtToken,
      meetalUser.branches[0].branch_id,
      logOut
    );
    setUserAgents(response);
  }

  useEffect(() => {
    if (activeTab === "Agents") {
      fetchAgents();
    }
  }, [activeTab]);

  const { logOut } = useContext(CognitoSessionContext);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  function handleInputLocationChange(e) {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      location: {
        ...formData.location,
        [name]: value,
      },
    });
  }

  async function sendForm() {
    setButtonLoading(true);
    const formattedAgentTypes = agentTypes.map((t) => {
      return t.value;
    });

    const body = {
      ...formData,
      agent_types: formattedAgentTypes,
      org_id: meetalUser.org_id,
      admin_id: meetalUser.meetal_user_id,
      branch_id: meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
    };

    for (let key in body) {
      if (!body[key]) {
        delete body[key];
      }
    }

    const response = await postAgent(
      cognitoUser.idToken.jwtToken,
      body,
      logOut
    );
    console.log(response);
    if (response.data.statusCode !== 200) {
      toast({
        title: "Erro ao salvar",
        description: "Tente novamente",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
    } else {
      toast({
        title: "Agente criado com sucesso",
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
    }
    setButtonLoading(false);
  }

  return (
    <>
      <SessionButton width="200px" onClick={onOpen}>
        Adicionar agente
      </SessionButton>
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        borderRadius="8px"
        padding="15px"
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Tipo</Th>
              <Th>Apelido</Th>
              <Th>Razão social/Nome</Th>
              <Th>CNPJ/CPF</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userAgents.map((a) => (
              <Tr key={a.agent_id}>
                <Td>{a.agent_id.split("-")[0]}</Td>
                <Td>{a.agent_types.join(", ")}</Td>
                <Td>{a.nickname}</Td>
                <Td>{a.brand_name || a.name}</Td>
                <Td>{a.cnpj || a.cpf}</Td>
                <Td>{a.status}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <CreateAgentDrawer
        isOpen={isOpen}
        onClose={onClose}
        formData={formData}
        handleInputChange={handleInputChange}
        agentTypes={agentTypes}
        setAgentTypes={setAgentTypes}
        handleInputLocationChange={handleInputLocationChange}
        saveAgent={sendForm}
        buttonLoading={buttonLoading}
      />
    </>
  );
}
