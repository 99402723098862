import {
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import Header from "../../components/Global/Header";
import Sidebar from "../../components/Global/Sidebar";
import AdminPageContainer from "../../components/Global/AdminPageContainer";
import AdminPageContent from "../../components/Global/AdminPageContent";
import SubHeader from "../../components/Global/SubHeader";
import Footer from "../../components/Global/Footer";
import { useState } from "react";
import AgentsTab from "./RegistrationsTabs/AgentsTab";
import MaterialsTab from "./RegistrationsTabs/MaterialsTab";
import EquipmentsTab from "./RegistrationsTabs/EquipmentsTab";

export default function RegistrationsPage() {
  const [activeTab, setActiveTab] = useState("Agents");

  return (
    <>
      <Header />
      <HStack>
        <Sidebar />
        <AdminPageContainer>
          <SubHeader>Cadastros</SubHeader>
          <AdminPageContent>
            <Tabs margin="35px 0 0 25px">
              <TabList>
                <Tab onClick={() => setActiveTab("Agents")}>Agentes</Tab>
                <Tab onClick={() => setActiveTab("Equipments")}>
                  Equipamentos
                </Tab>
                <Tab onClick={() => setActiveTab("Materials")}>Materiais</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <AgentsTab activeTab={activeTab} />
                </TabPanel>
                <TabPanel>
                  <EquipmentsTab activeTab={activeTab} />
                </TabPanel>
                <TabPanel>
                  <MaterialsTab activeTab={activeTab} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </AdminPageContent>
          <Footer />
        </AdminPageContainer>
      </HStack>
    </>
  );
}
