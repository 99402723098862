import axios from "axios";
import checkIfSessionIsValid from "../utils/checkIfSessionIsValid";

export async function postAgent(idToken, body, logOut) {
  const url = `https://64g2hkyd5i.execute-api.sa-east-1.amazonaws.com/dev/agent`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const JsonBody = JSON.stringify(body);

  const promise = axios.post(url, JsonBody, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return response;
}

export async function getAgents(idToken, branch_id, logOut) {
  const url = `https://64g2hkyd5i.execute-api.sa-east-1.amazonaws.com/dev/agent?branch_id=${branch_id}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const promise = axios.get(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return JSON.parse(response.data.body);
}

export async function getAgentByType(
  idToken,
  branch_id,
  agent_type,
  query,
  logOut
) {
  const url = `https://64g2hkyd5i.execute-api.sa-east-1.amazonaws.com/dev/agent?branch_id=${branch_id}&agent_type=${agent_type}&q=${query}`;
  const config = {
    headers: {
      Authorization: `${idToken}`,
    },
  };
  const promise = axios.get(url, config);
  const response = await checkIfSessionIsValid(promise, logOut);

  return JSON.parse(response.data.body);
}
