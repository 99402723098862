import { useContext, useEffect, useState } from "react";
import { SessionButton } from "../../../components/SessionComponents";
import {
  getMaterialLabels,
  getMaterials,
  postMaterial,
  postMaterialLabels,
  putMaterialLabels,
} from "../../../services/Materials";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { CognitoSessionContext } from "../../../contexts/CognitoSession";
import {
  CreateMaterialDrawer,
  LabelModal,
} from "../../../components/AdminComponents";

export default function MaterialsTab({ activeTab }) {
  const [userMaterials, setuserMaterials] = useState([]);
  const [userLabels, setUserLabels] = useState([]);
  const [newMaterialLabel, setNewMaterialLabel] = useState("");
  const [editedMaterialLabel, setEditedMaterialLabel] = useState({
    label_id: "",
    label_name: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formData, setFormData] = useState({
    material_name: "",
    label_id: "",
    measure_unit: "",
    cst: "",
    ncm: "",
    observations: "",
  });
  const {
    isOpen: drawerIsOpen,
    onOpen: drawerOnOpen,
    onClose: drawerOnClose,
  } = useDisclosure();
  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();

  const cognitoUser = JSON.parse(window.localStorage.getItem("CognitoUser"));
  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  const toast = useToast();

  async function fetchMaterials() {
    const response = await getMaterials(
      cognitoUser.idToken.jwtToken,
      meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
      logOut
    );

    setuserMaterials(JSON.parse(response.data.body));
  }

  useEffect(() => {
    if (activeTab === "Materials") {
      fetchMaterials();
    }
  }, [activeTab]);

  const { logOut } = useContext(CognitoSessionContext);

  async function getLabelsAndFormat() {
    const response = await getMaterialLabels(
      cognitoUser.idToken.jwtToken,
      meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
      logOut
    );
    const labels = JSON.parse(response.data.body).map((l) => {
      return { name: l.label_name, value: l.label_id };
    });
    setUserLabels(labels);
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  function validateNewLabel(newLabel) {
    if (newLabel.length < 3) {
      toast({
        title: "Erro ao criar grupo",
        description: "O grupo deve ter no mínimo 3 caracteres",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
      return false;
    }
    for (let i = 0; i < userLabels.length; i++) {
      if (userLabels[i].name === newLabel) {
        toast({
          title: "Erro ao criar grupo",
          description: "Esse nome já está sendo utilizado em outro grupo",
          status: "error",
          position: "bottom-right",
          isClosable: true,
        });
        return false;
      }
    }
    return true;
  }

  async function sendLabel() {
    const labelValidated = validateNewLabel(newMaterialLabel);
    if (labelValidated) {
      const body = {
        label_name: newMaterialLabel,
        org_id: meetalUser.org_id,
        admin_id: meetalUser.meetal_user_id,
        branch_id: meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
      };

      const response = await postMaterialLabels(
        cognitoUser.idToken.jwtToken,
        body,
        logOut
      );
      console.log(response);
      if (response.data.statusCode !== 200) {
        toast({
          title: "Erro ao criar grupo",
          description:
            "Ocorreu um erro ao tentar criar um novo grupo, tente novamente",
          status: "error",
          position: "bottom-right",
          isClosable: true,
        });
      } else {
        getLabelsAndFormat();
        toast({
          title: "Grupo criado com sucesso",
          status: "success",
          position: "bottom-right",
          isClosable: true,
        });
      }
    }
  }

  async function sendEditedLabel() {
    const labelValidated = validateNewLabel(editedMaterialLabel.label_name);

    if (labelValidated) {
      const body = {
        ...editedMaterialLabel,
        org_id: meetalUser.org_id,
        admin_id: meetalUser.meetal_user_id,
        branch_id: meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
      };

      const response = await putMaterialLabels(
        cognitoUser.idToken.jwtToken,
        body,
        logOut
      );
      if (response.data.statusCode !== 200) {
        toast({
          title: "Erro ao criar grupo",
          description:
            "Ocorreu um erro ao tentar alterar o nome grupo, tente novamente",
          status: "error",
          position: "bottom-right",
          isClosable: true,
        });
      } else {
        getLabelsAndFormat();
        toast({
          title: "Nome do grupo alterado com sucesso",
          status: "success",
          position: "bottom-right",
          isClosable: true,
        });
      }
    }
  }

  async function sendForm() {
    setButtonLoading(true);

    const body = {
      ...formData,
      org_id: meetalUser.org_id,
      admin_id: meetalUser.meetal_user_id,
      branch_id: meetalUser.branches[0].branch_id, //! pegar referente a branch selecionada
    };

    const response = await postMaterial(
      cognitoUser.idToken.jwtToken,
      body,
      logOut
    );
    console.log(response);
    if (response.data.statusCode !== 200) {
      toast({
        title: "Erro ao salvar",
        description:
          "Orreu um erro ao tentar salvar o material, tente novamente",
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
    } else {
      fetchMaterials();
      toast({
        title: "Material criado com sucesso",
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
    }
    setButtonLoading(false);
  }

  return (
    <>
      <SessionButton
        width="200px"
        onClick={async () => {
          getLabelsAndFormat();
          drawerOnOpen();
        }}
      >
        Adicionar
      </SessionButton>
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        borderRadius="8px"
        padding="15px"
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Nome do material</Th>
              <Th>Grupo do material</Th>
              <Th>Unidade de medida</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userMaterials.map((m) => (
              <Tr key={m.material_id}>
                <Td>{m.material_id.split("-")[0]}</Td>
                <Td>{m.material_name}</Td>
                <Td>{m.label_name}</Td>
                <Td>{m.measure_unit}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <CreateMaterialDrawer
        drawerIsOpen={drawerIsOpen}
        drawerOnClose={drawerOnClose}
        formData={formData}
        handleInputChange={handleInputChange}
        userLabels={userLabels}
        modalOnOpen={modalOnOpen}
        saveAgent={sendForm}
        buttonLoading={buttonLoading}
      />
      <LabelModal
        isOpen={modalIsOpen}
        onClose={modalOnClose}
        sendEditedLabel={sendEditedLabel}
        userLabels={userLabels}
        editedMaterialLabel={editedMaterialLabel}
        setEditedMaterialLabel={setEditedMaterialLabel}
        newMaterialLabel={newMaterialLabel}
        setNewMaterialLabel={setNewMaterialLabel}
        closeButtonOnClick={() => {
          setNewMaterialLabel("");
          modalOnClose();
        }}
        closeButtonText="Cancelar"
        acceptButtonOnClick={sendLabel}
        acceptButtonText="Adicionar"
      />
    </>
  );
}
