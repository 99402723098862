import { Box, Flex } from "@chakra-ui/react";
import { FiLogOut, FiSettings } from "react-icons/fi";
import SidebarButton from "../SidebarButton";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { CognitoSessionContext } from "../../contexts/CognitoSession";
import SidebarSubmenuButton from "../SidebarSubmenuButton";

export default function Sidebar() {
  const navigate = useNavigate();

  const { logOut } = useContext(CognitoSessionContext);

  const meetalUser = JSON.parse(window.localStorage.getItem("MeetalUser"));

  return (
    <Flex
      width={{ base: "200px", lg: "240px" }}
      height="calc(100vh - 80px)"
      backgroundColor="white"
      borderRight="1px solid"
      borderColor="gray.200"
      flexDirection="column"
      justifyContent="space-between"
      position="fixed"
      top="80px"
      left="0"
      zIndex="2"
    >
      <Box paddingLeft={{ base: "0", lg: "20px" }}>
        {/* <SidebarButton
          icon={<FiSettings />}
          onClick={() => navigate(`/admin/unidades`)}
          marginTop="25px"
          selected={window.location.href.endsWith("/unidades")}
        >
          Unidades
        </SidebarButton>
        <SidebarButton
          icon={<FiSettings />}
          onClick={() => navigate(`/admin/usuarios`)}
          marginTop="25px"
          selected={window.location.href.endsWith("/usuarios")}
        >
          Usuários
        </SidebarButton> */}
        <SidebarButton
          icon={<FiSettings />}
          onClick={() => navigate(`/admin/cadastros`)}
          marginTop="25px"
          selected={window.location.href.endsWith("/cadastros")}
        >
          Cadastros
        </SidebarButton>
        <SidebarButton
          icon={<FiSettings />}
          onClick={() => {
            navigate(`/admin/transportes`);
          }}
          marginTop="25px"
          selected={
            window.location.href.endsWith("/transportes") ||
            window.location.href.endsWith("/cacambas")
          }
        >
          Logística
        </SidebarButton>
        <Box
          display={
            window.location.href.endsWith("/transportes") ||
            window.location.href.endsWith("/cacambas")
              ? "box"
              : "none"
          }
          paddingLeft={{ base: "0", lg: "20px" }}
        >
          <SidebarSubmenuButton
            onClick={() => navigate(`/admin/transportes`)}
            marginTop="15px"
            selected={window.location.href.endsWith("/transportes")}
          >
            Transportes
          </SidebarSubmenuButton>
          <SidebarSubmenuButton
            onClick={() => navigate(`/admin/cacambas`)}
            marginTop="15px"
            selected={window.location.href.endsWith("/cacambas")}
          >
            Caçambas
          </SidebarSubmenuButton>
        </Box>
        <SidebarButton
          icon={<FiSettings />}
          onClick={() => navigate(`/admin/estoque`)}
          marginTop="25px"
          selected={window.location.href.endsWith("/estoque")}
        >
          Estoque
        </SidebarButton>
        {/* <SidebarButton
          icon={<FiSettings />}
          onClick={() =>
            navigate(`/admin/configuracoes`)
          }
          marginTop="25px"
          selected={window.location.href.endsWith("/configuracoes")}
        >
          Configurações
        </SidebarButton> */}
      </Box>
      <Flex
        height="80px"
        width="100%"
        alignItems="center"
        paddingLeft="20px"
        backgroundColor="#FCFDFE"
        borderTop="1px solid"
        borderColor="gray.200"
      >
        <SidebarButton icon={<FiLogOut />} onClick={logOut} marginTop="0">
          Sair
        </SidebarButton>
      </Flex>
    </Flex>
  );
}
