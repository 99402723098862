import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  Link,
  PinInput,
  PinInputField,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { brandColorBlue, brandColorOrange } from "../GlobalStyles";
import { HiExclamation } from "react-icons/hi";
import { FiMail, FiClock } from "react-icons/fi";

export function SessionHeading({ children }) {
  return (
    <Heading
      size="lg"
      fontWeight="600"
      margin="40px 0 15px 0"
      color={brandColorBlue}
    >
      {children}
    </Heading>
  );
}

export function SessionFormLabel({ children }) {
  return (
    <FormLabel size="md" fontWeight="600" marginTop="25px">
      {children}
    </FormLabel>
  );
}

export function SessionInput({
  size,
  placeholder,
  type,
  value,
  name,
  maxlength,
  onChange,
  autoFocus,
  disabled,
  isInvalid,
  errorMessage,
}) {
  return (
    <>
      <InputGroup>
        <Input
          size={size ? size : "lg"}
          borderColor="gray.200"
          color={brandColorBlue}
          backgroundColor="white"
          _placeholder={{
            color: "gray.400",
          }}
          _hover={{
            borderColor: "gray.900",
          }}
          _focus={{
            borderColor: "gray.900",
            outline: "3px solid",
            outlineColor: "blue.50",
          }}
          _disabled={{
            color: "gray.400",
            backgroundColor: "gray.100",
            borderColor: "gray.300",
            cursor: "not-allowed",
          }}
          _invalid={{
            border: "2px",
            borderColor: "red.500",
          }}
          placeholder={placeholder}
          type={type}
          value={value}
          name={name}
          maxLength={maxlength}
          onChange={onChange}
          autoFocus={autoFocus}
          disabled={disabled}
          isInvalid={isInvalid}
        />
      </InputGroup>
      {isInvalid ? (
        <Flex gap="6px">
          <Icon
            as={HiExclamation}
            boxSize={5}
            color="red.500"
            marginTop="10px"
          />
          <FormErrorMessage fontSize="medium" fontWeight="500" color="#6B7177">
            {errorMessage}
          </FormErrorMessage>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
}

export function SessionFormHelperText({ children }) {
  return (
    <FormHelperText fontSize="medium" fontWeight="500" color="#6B7177">
      {children}
    </FormHelperText>
  );
}

export function SessionCheckBox({ children, onChange, disabled }) {
  return (
    <Checkbox
      size="lg"
      colorScheme="green"
      marginTop="25px"
      disabled={disabled}
      onChange={onChange}
    >
      {children}
    </Checkbox>
  );
}

export function SessionButton({
  children,
  width,
  onClick,
  type,
  isDisabled,
  isLoading,
  hasMargin,
}) {
  return (
    <Button
      size="lg"
      width={width ? width : "100%"}
      marginTop={hasMargin ? "40px" : ""}
      backgroundColor={brandColorBlue}
      color="white"
      _hover={{
        backgroundColor: "gray.900",
      }}
      _focus={{
        border: "3px solid",
        borderColor: "blue.50",
      }}
      _disabled={{
        backgroundColor: "gray.300",
        cursor: "not-allowed",
      }}
      type={type}
      onClick={onClick}
      isDisabled={isDisabled}
      isLoading={isLoading}
      loadingText="Carregando"
    >
      {children}
    </Button>
  );
}

export function SessionTooltip({ children, isDisabled, text }) {
  return (
    <Tooltip
      backgroundColor="gray.900"
      marginBottom="-10px"
      placement="top"
      hasArrow
      label={text}
      isDisabled={isDisabled}
    >
      <span>{children}</span>
    </Tooltip>
  );
}

export function SessionTextWithLink({ text, linkText, href }) {
  return (
    <Text size="md" fontWeight="600" marginTop="40px" color={brandColorBlue}>
      {text}
      <Link color={brandColorOrange} textDecoration="underline" href={href}>
        {linkText}
      </Link>
    </Text>
  );
}

export function SessionMailIcon() {
  return <Icon as={FiMail} boxSize={70} color="black" marginTop="50px" />;
}

export function SessionText({ children }) {
  return (
    <Text
      fontSize="lg"
      fontWeight="400"
      marginTop="15px"
      color={brandColorBlue}
      textAlign="center"
    >
      {children}
    </Text>
  );
}

export function SessionTextBold({ children }) {
  return (
    <Text as="b" fontSize="lg" color={brandColorBlue}>
      {children}
    </Text>
  );
}

export function SessionPinInputs({
  onComplete,
  onChange,
  isInvalid,
  isDisabled,
}) {
  const numberOfInputFields = ["1", "2", "3", "4", "5", "6"];
  return (
    <>
      <HStack marginTop="40px">
        <PinInput
          size={{ base: "md", sm: "lg" }}
          onComplete={onComplete}
          onChange={onChange}
          isInvalid={isInvalid}
          isDisabled={isDisabled}
          autoFocus
        >
          {numberOfInputFields.map((key) => (
            <PinInputField
              key={key}
              _placeholder={{
                color: "gray.400",
              }}
              _hover={{
                borderColor: "gray.900",
              }}
              _focus={{
                borderColor: "gray.900",
                outline: "3px solid",
                outlineColor: "blue.50",
              }}
              _disabled={{
                color: "gray.400",
                backgroundColor: "gray.100",
                borderColor: "gray.300",
                cursor: "not-allowed",
              }}
              _invalid={{
                border: "2px",
                borderColor: "red.500",
              }}
            />
          ))}
        </PinInput>
      </HStack>
      {isInvalid ? (
        <FormControl isInvalid display="flex" justifyContent="center" gap="6px">
          <Icon
            as={HiExclamation}
            boxSize={5}
            color="red.500"
            marginTop="10px"
          />
          <FormErrorMessage fontSize="medium" fontWeight="500" color="#6B7177">
            Código errado, verifique e tente novamente
          </FormErrorMessage>
        </FormControl>
      ) : (
        <></>
      )}
    </>
  );
}

export function SessionTextButton({ children, onClick, isDisabled }) {
  return (
    <Button
      size="lg"
      marginTop="30px"
      variant="link"
      color={brandColorOrange}
      textDecoration="underline"
      onClick={onClick}
      isDisabled={isDisabled}
    >
      {children}
    </Button>
  );
}

export function SessionClockIcon() {
  return <Icon as={FiClock} boxSize={58} color="black" marginTop="80px" />;
}

export function SessionPopover({ children }) {
  return (
    <Popover placement="top">
      <PopoverTrigger>
        <span>{children}</span>
      </PopoverTrigger>
      <PopoverContent border="1px solid" borderColor="#DFE4ED">
        <PopoverHeader fontWeight="bold" color="black">
          Segurança de senha
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody color="gray.600">
          <Text as="b">A sua senha deve conter, ao menos:</Text>
          <Text>• 8 caracteres;</Text>
          <Text>• uma letra minúscula</Text>
          <Text>• uma letra maiúscula;</Text>
          <Text>• um número;</Text>
          <Text>• um caractere especial;</Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
